.calendar_modal {
  @include appmodal(auto, 320px);
  @include respond-min($medium-760px) {
    @include appmodal(100%, 700px);
  }
  @include respond-max($medium-700px) {
    @include appmodal(100%, 320px);
  }
  @include respond-max($smaller-410px) {
    @include appmodal(100%, 85vw);
  }
  .book-visit {
    margin: 20px 20px 0 20px;
    font-size: 18px;

    @include respond-max($medium-760px) {
      margin: 20px 20px 0 15px;
    }
  }
  &__wrapper {
    padding: 0 10px;
    @include respond-min($medium-760px) {
      padding: 10px 15px;
      display: flex;
      flex-direction: row;
      gap: 15px;
    }

    .form-items label {
      font-size: 14px;
      @include respond-max($medium-700px) {
        font-size: 12px;
      }
    }
    .form-items select {
      font-size: 14px;
      @include respond-max($medium-700px) {
        font-size: 12px;
      }
    }
    .form-items .text-field .select-wrap select {
      padding: 10px 10px;
      @include respond-max($medium-700px) {
        padding: 10px 10px;
      }
    }
  }
}
.form__group {
  padding: 2px 0;
}


.share_popup{
  background: rgb(255, 254, 254);
  padding: 25px;
  border-radius: 15px;
  top: 10%;
  max-width: 380px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.1);
  transform: translate(-50%, -50%) scale(1.2);
  transition: top 0s 0.2s ease-in-out,
              opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;
}
.share_popup.show{
  top: 50%;
  left: 50%;
  opacity: 1;
  pointer-events: auto;
  transform:translate(-50%, -50%) scale(1);
  transition: top 0s 0s ease-in-out,
              opacity 0.2s 0s ease-in-out,
              transform 0.2s 0s ease-in-out;

}
.share_popup :is(header, .share_popup_icons, .share_popup_field){
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.share_popup header{
  padding-bottom: 15px;
  border-bottom: 1px solid #ebedf9;
}
header span{
  font-size: 21px;
  font-weight: 600;
}
header .share_popup_close, .share_popup_icons a{
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
} 
header .close{
  color: #878787;
  font-size: 17px;
  background: #f3f3f3;
  height: 33px;
  width: 33px;
  cursor: pointer;
}
header .share_popup_close:hover{
  background: #ebedf9;
}
.share_popup_content{
  margin: 20px 20px 0 20px;
}
.share_popup_icons{
  margin: 15px 0 20px 0;
}
.share_popup_content p{
  font-size: 16px;
}
.share_popup_content .share_popup_icons a{
  height: 50px;
  width: 50px;
  font-size: 20px;
  text-decoration: none;
  border: 1px solid transparent;
}
.share_popup_icons a i{
  transition: transform 0.3s ease-in-out;
}

.share_popup_field{
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #757171;
}
.share_popup_field.active{
  border-color: #7d2ae8;
}
.share_popup_field i{
  width: 50px;
  font-size: 18px;
  text-align: center;
}
.share_popup_field.active i{
  color: #7d2ae8;
}
.share_popup_field input{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}
.share_popup_field button{
  color: #fff;
  padding: 5px 18px;
  background: #7d2ae8;
}
.share_popup_field button:hover{
  background: #8d39fa;
}
