.chatroom {
  flex: 0.7;
  display: flex;
  flex-direction: column;

  @include respond-max($small-600px) {
    height: 74vh;
  }

  .chat__exit-btn {
    display: none;
    @include respond-max($small-600px) {
      display: block;
    }
  }
}

.chatroom__header {
  display: flex;
  padding: 15px;
  align-items: center;
  // background-color: #ececec;
  border-bottom: 1px solid #d8d8d8;

  .sidebar_icon {
    height: 3rem;
    width: 3rem;
    border-radius: 50px;
    img {
      object-fit: fill;
    }
  }
}

.chatroom__headerinfoleft {
  flex: 1;
  padding-left: 15px;
  // font-size: 1.6rem;
  h3 {
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
  }
  span {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
}

.chatroom__headerinfoleft > p {
  color: gray;
}

.chatroom__head .chatroom__header__inforight {
  display: flex;
  align-items: center;
  min-width: 80px;
}

.chatroom__body {
  flex: 1;
  padding: 2.5rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cecece;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.chatroom__message {
  position: relative;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 2.5px;
  padding: 5px 8px;
  background: #f0eff4;
  border-radius: 7px;
  width: fit-content;
  margin-top: 2.5px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 2px;
  // span {
  //   font-size: 8px;
  // }
  img {
    height: 150px;
    width: 150px;
    margin: 5px 0;
    border-radius: 7px;
    object-fit: cover;
  }
}

.chatroom__username {
  position: absolute;
  top: -15px;
  font-weight: bold;
  font-size: xx-small;
}
.translated_in {
  color: $primary-color;
}

.chatroom__messagetimestamp {
  margin-left: 10px;
  font-weight: 500;
  font-size: xx-small;
}

.chatroom__messagerecierver {
  margin-left: auto;
  background: linear-gradient(
    -29deg,
    rgb(14, 135, 127) 0%,
    rgb(10, 197, 184) 100%
  );
  color: white;
}

/* last div */
.chatroom__footer {
display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    background-color: white;
}

.chatroom__footer > form {
  flex: 1;
  display: flex;
  // height: 200px;
  align-items: end;
}

.chatroom_input_image {
    flex: 1 1;
    border-radius: 10px;
    width: 90%;
    margin: 8px 5px;
    background-color: #f2ecec;
  input {
    width: 99%;
    border: none;
    padding: 13px;
    // border-radius: 50px;
    background-color: transparent;
    margin: 2px;
  }
  .chat_image_preview {
    height: 120px;
    width: 100%;

    .image_tile {
      height: 100px;
      width: 100px;
      margin-top: 10px;
      margin-left: 10px;
      position: relative;
      img {
        height: 100px;
        width: 100px;
        object-fit: cover;
        border-radius: 10px;
      }
      .image_remove {
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: red;
        top: -8px;
        right: 0;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

.chatroom__footer > form > button {
    border: none;
    color: white;
    border-radius: 50%;
    padding-bottom: 8px;
    cursor: pointer;
}

.chatroom__footer > form > input:focus {
  outline-width: 0;
}
.chatroom__footer > form > button:focus {
  outline-width: 0;
}

.chatroom__footer > .MuiSvgIcon-root {
  padding: 10px;
  color: gray;
}
.chat_image_pick {
  input[type="file"] {
    height: 40px;
    width: 40px;
    display: inline-block;
    padding: 40px 0 0 0;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #555;
    background: url("../../../assets/add-image.png") center center no-repeat
      #f8f7f7;
    border-radius: 10px;
    background-size: 20px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
}

.chatroom__footer > form > button img {
    height: 20px;
    width: 20px;
}

.basic_chat_box {
  max-width: 1000px;
}
