*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 55.5%;

  @include respond-max($largest-1100px) {
    font-size: 50%;
  }

  @include respond-min($screen-1525px) {
    font-size: 62.5%;
  }

  // @include respond-min($screen-1900px) {
  //   font-size: 70%;
  // }

  @include respond-max($small-540px) {
    font-size: 40%;
  }
}

body {
  box-sizing: border-box;
  font-family: $primary-font-stack;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 500;
}
a {
  outline: none;
}
button {
  border: none;
  outline: none;
}

input,
input:focus,
input:disabled,
input:-moz-user-disabled,
input:active,
input:default,
input:indeterminate,
input:invalid,
input:-moz-ui-invalid,
input:-moz-submit-invalid,
input:required,
input:valid,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  outline: none;
  // background: transparent;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $white inset !important;
  -webkit-text-fill-color: $primary-text-color !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.main {
  margin-top: 12rem;

  @include respond-max($largest-1000px) {
    margin-top: 12rem;
  }
  @include respond-max($larger-900px) {
    margin-top: 12rem;
  }
  @include respond-max($small-600px) {
    margin-top: 12rem;
  }
  @include respond-max($smaller-440px) {
    margin-top: 12rem;
  }
}
.confirmation-modal {
  // background-color: $white;
  // padding: 20px 20px 10px 20px;
  // display: flex;
  // flex-direction: column;
  // box-shadow: $box-shadow;
  // border-radius: 10px;
  // font-size: 16px;
  // gap: 10px;
  // // height: 100px;
  // justify-content: space-between;
  // font-weight: bold;
  // width: 250px;

  @include appmodal(auto, 500px);
  @include respond-max($small-600px) {
    @include appmodal(auto, 350px);
    white-space: pre-line;
  }
  .title {
    margin-top: 1.5rem;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px;
  }
  .btn__s {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    padding: 0 15px 10px 15px;

    .btn {
      font-size: 16px;
      color: $primary-color;
      font-weight: bold;
      background-color: transparent;
      padding: 10px 10px;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: $hover-color;
      }
    }
  }
}

.main_content {
  max-width: $app-max-width;
  margin: auto;

  @include respond-max($largest-1360px) {
    padding: 0 80px;
  }
  @include respond-max($largest-1000px) {
    padding: 0 20px;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 100vh;
}

.page-content {
  margin-top: 15rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-max($smaller-470px) {
    margin-top: 25rem;
  }
}

.store_links {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  // @include respond-max($small-540px) {
  //   margin-top: 20rem;
  // }
  .apple-icon {
    width: 105px;
    height: 45px;
    @include respond-max($small-540px) {
      width: 78px;
      height: 35px;
      // margin-bottom: 3px;
    }
  }
  .google-icon {
    width: 115px;
    height: 52px;
    @include respond-max($small-540px) {
      width: 85px;
      height: 38px;
    }
  }
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
.gap5 {
  gap: 5px !important;
}
.gap10 {
  gap: 10px !important;
}
.gap20 {
  gap: 20px !important;
}

.justify-space-between {
  justify-content: space-between !important;
}
.justify-space-evenly {
  justify-content: space-evenly !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-flex-start {
  justify-content: flex-start !important;
}
.justify-flex-end {
  justify-content: flex-end !important;
}
.align-item-center {
  align-items: center !important;
}
.align-item-end {
  align-items: flex-end !important;
}

.align-item-start {
  align-items: flex-start !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}
.flex-row {
  display: flex !important;
  flex-direction: row !important;
}
