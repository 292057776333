.main-business {
  .service-provider-section {
    padding: 0;
    position: relative;
    margin-bottom: 15rem;
  }

  .btn__stack {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 5rem 0 3rem 0;

    @include respond-max($smaller-410px) {
      padding: 1rem 2rem;
    }

    .primary__btn {
      width: 20rem;
      margin: 0;

      @include respond-max($smaller-410px) {
        flex: 1;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
}
.portfolio_opinions {
  display: flex;
  box-shadow: $box-shadow;
  border-radius: 10px;
}
.invalid__business {
  display: block;
  padding-top: 20rem;
  text-align: center;
  font-size: 2rem;
}
.home__btn {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}
