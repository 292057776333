.terms_container {
    margin-top: 18rem;
    margin-bottom: 8rem;

  .terms_heading {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
    color: #535353;
  }
  .main__term{
    padding: 0 2.3rem;
    font-size: 1.2rem;
  }
  .description__list {
    font-size: 16px;
    list-style: auto;
  }
  .strong{
    font-size: 30px;
  }
}

.terms_container p ul {
  font-size: 1.2rem;
  }