@mixin selectWithPrefix($ic) {
  .select-wrap {
    position: relative;

    select {
      padding: 15px 10px;
      @if $ic {
        padding: 15px 45px;
        @include respond-min($largest-1000px) {
          padding: 15px 55px;
        }
      }
    }
    .prefix-icon {
      // display: none;
      @if $ic {
        position: absolute;
        top: 15px;
        left: 18px;
        height: 20px;
        width: 20px;
        // @include respond-min($extra-large-1460px) {
        //   top: 15px;
        // }
      }
    }
  }
}
.profile-complete-modal {
  @include appmodal(80vh, 40vw);
  @include respond-max($smaller-470px) {
    @include appmodal(100vh, 100vw);
  }
  .profile-detail-intro {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 50px 20px 10px 20px;
    gap: 8px;
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 15px;
    }
  }
}
.profile-complete-form {
  margin-top: 2rem;
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  // background-color: chartreuse;

  .form-image {
    top: 0;
    position: absolute;
    height: 120px;
    width: 120px;
    margin-bottom: 10px;
    object-fit: cover;
    img {
      border-radius: 20px;
      object-fit: cover;
      margin-top: 5px;
    }

    &:hover {
      display: none;
      opacity: 0.5;
      cursor: pointer;
    }
  }
  .image-input {
    position: relative;
    .error-image {
      color: #bf1650;
      font-size: 14px;
      padding: 5px;
    }

    .remove-image {
      position: absolute;
      top: 15px;
      right: 10px;
      height: 16px;
      width: 16px;
      background-color: red;
      border-radius: 100%;
    }

    input[type="file"] {
      height: 120px;
      width: 120px;
      display: inline-block;
      padding: 120px 0 0 0;

      overflow: hidden;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: url("../../assets/add-image.png") center center no-repeat
        #f8f7f7;
      border-radius: 20px;
      background-size: 60px 60px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
        margin-bottom: 0;
        margin-top: 5px;
      }
    }
  }
}
.form-body {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  .address-select-text-field {
    width: 100%;
    position: relative;
    // @include respond-max($largest-1200px) {
    //   width: 49%;
    // }
    // @include respond-max($large-850px) {
    //   width: 100%;
    // }
    .text-field {
      width: 100%;
    }
    .current-location-icon {
      position: absolute;
      height: 45px;
      width: 45px;
      top: 35px;
      right: 1px;
      padding: 10px 10px;
      background-color: $white;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
    .address-auto-complete {
      height: 300px;
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      position: absolute;
      z-index: 9;
      width: 100%;

      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;

      .address-item {
        font-size: 16px;
        color: $black;
        padding: 20px;
        cursor: pointer;
        border-bottom: 0.5px solid rgb(158, 158, 158);
      }
    }
  }
  .address-preview-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    .item {
      width: 100%;
      font-size: 14px;
      color: $black;
      padding: 10px;
      cursor: pointer;
      font-weight: 300;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      border: 1px solid $button-disabled-color;
      .edit-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
      .edit-icon {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.form-items {
  label {
    font-size: 1.5rem;
     @include respond-max($large-850px) {
      font-size: 1.8rem;
     }    
  }
  // select,
  textarea,
  input {
    width: 100%;
    border-radius: 4px;
   // border: 1px solid $button-disabled-color;
    background-color: white;
    padding: 0px;
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 3px;
    font-size: 1.5rem;
    @include respond-max($large-850px) {
      font-size: 1.8rem;
     }   
  }
  select {
    // padding: 10px 5px;
  }

  textarea:focus,
  input:focus {
   // outline: 1px solid $primary-color;
  }

  input[type="submit"]:disabled {
    background-color: $button-disabled-color;
  }
  input[type="submit"] {
  //  background-color: $primary-color;
    color: $white;
    border-radius: 5px;
    margin-top: 0px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  .text-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    @include selectWithPrefix(false);
  }
  .text-field-with-prefix {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    @include selectWithPrefix(true);
  }

  p {
    color: #bf1650;
    font-size: 12px;
  }

  p::before {
    display: inline;
    content: "⚠ ";
  }

  select {
    width: 100%;
    /* styling */
    background-color: white;
    border: thin solid $primary-color;
    border-radius: 4px;
    display: inline-block;
    // font: inherit;
    line-height: 1.5em;
    padding: 0 3.5em 0 1em;
    color: $grey;
    font-size: 14px;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 10px),
      calc(100% - 15px) calc(1em + 10px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 0px 3.1em;
    background-repeat: no-repeat;

    select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }
}
