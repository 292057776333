.aboutus {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 7rem;
  margin-top: 2rem;
  color: $primary-text-color;

  @include respond-max($largest-1260px) {
    margin-top: 2rem;
  }

  @include respond-max($larger-930px) {
    margin-top: 15rem;
  }

  @include respond-max($small-600px) {
    margin-top: 9rem;
  }  

   @include respond-max($smaller-470px) {
    margin-top: 10rem;
  } 

   @include respond-max(19em) {
    margin-top: 5rem;
  } 
  

  &__desc {
    max-width: 60%;
    margin: 3rem auto 3rem auto;
    text-align: center;
    font-weight: 400;
    line-height: 30px;
    font-size: 16px;
    @include respond-max($medium-640px) {
      line-height: 24px;
      max-width: 80%;
    }

    span {
      font-weight: bold;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    @include respond-max($medium-640px) {
      flex-direction: column;
      gap: 4rem;
    }
    .aboutus-detail-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      text-align: center;
      justify-content: center;
      gap: 10px;
      background-color: $white;
      box-shadow: $box-shadow;
      height: 25rem;
      padding: 10px;
      border-radius: 10px;

      @include respond-max($medium-640px) {
        width: 90%;
      }
    }
    .aboutus-detail-card-image {
      width: 14rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 40%;
      }
    }
    .aboutus-detail-card-title {
      font-size: 20px;
      font-weight: 500;
      @include respond-max($larger-930px) {
        font-size: 16px;
      }
    }
    .aboutus-detail-card-desc {
      //   max-width: 22rem;
      font-weight: 400;
      font-size: 16px;
      @include respond-max($larger-930px) {
        font-size: 14px;
      }
    }
  }
}
