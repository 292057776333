// ! BUSINESS CHANGES
.select__form-group {
  position: relative;
  display: flex;
  align-items: center;

  .select__caret {
    position: absolute;
    width: 1rem;
    height: 1rem;
    z-index: 900;
    top: 1rem;
    right: 1rem;
  }
  .select {
    background: transparent;
    padding: 2rem;
    border: 0.5px solid #8e8e8e;
    border-radius: 7px;
    color: #8e8e8e;
    flex: 1;
    font-size: 1.6rem;

    appearance: none;
    -webkit-appearance: none;
    &:active,
    &:focus {
      outline: none;
    }
  }
}
