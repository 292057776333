// * TOP NAV

.main__navigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;
  background-color: #fff;
}
.main__business_navigation {
  position: fixed;
  top: 0;
  width: calc(100% - 32rem);
  margin-left: 30rem;
  z-index: 90;
  background-color: #fff;
  @include respond-max($smaller-440px) {
    margin-left: 20rem;
    width: calc(100% - 20rem);
  }
}
.nav-top,
.top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  max-width: $app-max-width;
  margin: auto;
  // flex-direction: row;
  transition: all 0.4s;
  gap: 10px;
  border: 1px solid #d8d8d8;
  border-top: none;
  border-right: none;
  border-left: none;

  &-transformed {
    transform: translateX(40rem);
  }

  @include respond-max($largest-1360px) {
    padding: 2rem 80px;
  }
  @include respond-max($largest-1000px) {
    flex-wrap: wrap;
    padding: 2rem 20px;
  }

  // @include respond-max($small-600px) {
  //   padding: 2rem 20px;
  // }

  @include respond-max($smaller-440px) {
  //  flex-direction: column;
  //  align-items: flex-start;
  }
  .top-nav-links-user {
    width: 30rem;
    align-items: center;
    display: flex;

    @include respond-max($large-820px) {
      width: 100%;
      justify-content: center;
      margin-top: 2rem;
    }
  }
  .nav__user-icon {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    cursor: pointer;
  }
  .nav__user-link {
    list-style-type: none;
    position: relative;

    @include respond-max($large-820px) {
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    .alert-badge {
      background-color: red;
      border-radius: 50%;
      height: 1.2rem;
      width: 1.2rem;
      position: absolute;
      top: -2px;
      right: 0;
    }
  }
  .nav__user-link.user-name {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0 2rem;
    color: $primary-text-color;
  }
}

.nav__logo {
  height: 4rem;
  background-size: contain;
  margin-top: 6px;

  @include respond-max($smaller-410px) {
    margin-right: 2rem;
    height: 3rem;
  }
}

.nav__search {
  display: flex;
  align-items: center;
  // border: 0.82px solid #535353;
  border-radius: 10px;
  position: relative;

  background-color: $white;
  box-shadow: $box-shadow;
  // @include respond-max($smaller-410px) {
  //   flex: 1;
  // }

  &-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 5px;
  }
  &-input,
  .nav__location-label {
    border: none;
    font-size: 1.6rem;
    color: $primary-text-color;
    font-family: inherit;
    outline: none;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $primary-text-color;
      opacity: 1; /* Firefox */
    }

    @include respond-max($smaller-410px) {
      font-size: 1.4rem;
    }
  }
}
.nav-search-wrapper {
  height: 400px;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  position: absolute;
  top: 55px;
  border-radius: 10px;
  z-index: 99999;
  padding: 15px;
  gap: 15px;
  overflow-y: auto;

  label {
    font-size: 16px;
    font-weight: bold;
  }
  @include respond-max($smaller-410px) {
    top: 35px;
  }
  .service {
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
  }
  &-chip-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    &-chip {
      cursor: pointer;
      padding: 10px;
      border-radius: 20px;
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
      font-size: 14px;
    }
  }
}
.n-search {
  width: 500px;
  @include respond-max($largest-1360px) {
    width: 33vw;
  }
  // @include respond-max($largest-1200px) {
  //   width: 33vw;
  // }
  @include respond-max($largest-1000px) {
    width: 30vw;
  }
  @include respond-max($larger-900px) {
    width: 60vw;
    margin-right: 10px;
  }

  @include respond-max($smaller-440px) {
    width: 90vw;
    margin-top: 0;
  }
  input {
    width: 450px;
    @include respond-max($largest-1360px) {
      width: 28vw;
    }
    // @include respond-max($largest-1200px) {
    //   width: 28vw;
    // }
    @include respond-max($largest-1000px) {
      width: 25vw;
    }
    @include respond-max($larger-900px) {
      width: 50vw;
    }

    @include respond-max($smaller-440px) {
      width: 80vw;
    }
  }
}

.nav__text-search .nav__search-icon {
  // margin-right: 3rem;
}

.nav__text-search,
.nav__location-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.nav__location-search {
  border-left: 0.82px solid #d8d8d8;
  padding-right: 4rem;

  @include respond-max($smaller-410px) {
    display: none;
  }
}

.nav__top-links,
.top-nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
//  width: 50rem;

  @include respond-max($larger-900px) {
    &:not(.top-nav-links-user) {
    //  margin-top: 2rem;
    //  width: 100%;
    //  justify-content: flex-end;
    //  gap: 10px;
    }
  }
  @include respond-max($small-540px) {
    &:not(.top-nav-links-user) {
     // margin-top: 10px;
    }
  }
  .primary__btn {
    width: 150px;
    @include respond-max($small-540px) {
      width: 100px;
    }
  }
}

.nav__top-link {
  font-size: 1.6rem;
  color: $primary-text-color;
  text-decoration: none;

  @include respond-max($smaller-410px) {
    font-size: 1.2rem;
  }
}

// * BOTTOM NAV
.nav__bottom {
height: 0;
    padding: 0;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d8d8d8;
  border-left: none;
  border-right: none;
  width: 100%;

  @include respond-max($larger-900px) {
    height: 0;
    padding: 0;
  }

  .category {
    @include respond-min($larger-900px) {
      // clear: both;
      // display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &-links {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    list-style-type: none;
    font-size: 1.4rem;
    transition: all 0.5s;
    max-width: $app-max-width;
    margin: auto;
    text-align: center;
    overflow: hidden;
    // @include respond-max($largest-1260px) {
    //   padding: 0 20px;
    // }
    // @include respond-max($largest-1100px) {
    //   width: 90%;
    // }
    @include respond-max($largest-1360px) {
      padding: 0 80px;
    }
    @include respond-max($largest-1000px) {
      padding: 0 20px;
    }


      position: absolute;
      // top: 23rem;
      flex-direction: column;
      z-index: 900;
      background-color: #fff;
      font-size: 2rem;
      width: 100%;
      height: 0;
      overflow: hidden;

      &-opened {
        min-height: 28rem;
        padding: 2rem 0;
        border-bottom: 1px solid #d8d8d8;
      }

      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }
      .category {
        min-width: 60px;
        text-align: center;
      }
    
  }

  &-link {
    color: $primary-text-color;
    text-decoration: none;

    // text-transform: capitalize;
  }

  &-link-all {
    color: $primary-color;
    // margin-left: 1rem;

    @include respond-min($larger-900px) {
      width: 140px;
      clear: both;

      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.nav-bottom-opener-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nav__bottom-opener {
  height: 4rem;
  width: 4rem;
  margin-left: 12px;
  // margin-right: 2rem;
  display: block;
  cursor: pointer;


  @include respond-max($small-540px) {
    height: 3rem;
    width: 3rem;
  }
}
// ! BUSINESS CHANGES
// * === SIDE NAV
.side__nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 30rem;
  background-color: #fff;
  border-right: 1px solid #d8d8d8;
  height: 100vh;
  transform: translateX(-32rem);
  transition: all 0.4s;
  z-index: 90000;

  @include respond-max($smaller-440px) {
    width: 20rem;
  }

  &-opened {
    transform: translateX(0);
  }

  &-items {
    margin-top: 2rem;
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 1.6rem 4rem;
    font-size: 1.8rem;
    text-transform: capitalize;

    cursor: pointer;

    &-active {
      color: $primary-color;

      .side__nav-icon {
        fill: $primary-color;
      }
    }

    @include respond-max($medium-750px) {
      padding: 2rem;
    }
  }

  &-icon {
    width: 3rem;
    height: 3rem;
    margin-right: 4rem;
    fill: $primary-text-color;

    @include respond-max($medium-750px) {
      margin-right: 2rem;
    }
  }

  &-opener {
    width: 4rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;

    @include respond-max($smaller-440px) {
      width: 3rem;
      right: 1rem;
    }
  }
}

.side__nav .search__form-group,
.top-nav.business .search__form-group {
  display: flex;
  align-items: center;
  border: 0.82px solid #d8d8d8;
  padding: 1rem;
  margin: 0 2rem;

  .nav__user-icon {
    width: 2rem;
    cursor: pointer;

    height: 2rem;
    object-fit: cover;
  }

  input {
    border: none;
    width: 80%;
    color: $primary-text-color;
    font-family: inherit;
    outline: none;
  }

  &:focus-within {
    outline: 1px solid $primary-color;
  }
}

.side__nav .search__form-group {
  display: none;

  @include respond-max($medium-750px) {
    display: flex;
  }
}

.top-nav.business .search__form-group {
  @include respond-max($medium-750px) {
    display: none;
  }
}

.top-nav.business {
  border: none;

  @include respond-max($larger-900px) {
    padding: 2rem 2rem 2rem 2rem;
  }

  @include respond-max($small-540px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .main__logo {
    display: flex;
    align-items: center;

    &-text {
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.4rem;
      color: $primary-text-color;

      span {
        color: $primary-color;
      }
    }
  }

  .top-nav-links-user {
    width: 12rem;
  }
}
.com__logo {
  height: 4rem;
  margin-top: 3rem;
  background-size: contain;

  @include respond-max($smaller-410px) {
  }
}

.dropdown {
  position: relative;
  cursor: pointer;
}
.dropdown-box {
  width: 160px;
  padding-left: 12px;
  position: absolute;
  background-color: white;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  cursor: pointer;
  font-weight: 700;
}

.dropdown-content {
  font-size: 14px;
  padding-left: 20px;
  //border-left: 2px solid $primary-color;
}

.dropdown-content li {
  color: black;
  padding: 10px 15px 10px 0px;
  text-decoration: none;
  display: block;
  letter-spacing: 1px;
  font-weight: 400;
}

.border-radius-50 {
  border-radius: 50%;
}
