.client-main {
  max-width: $app-max-width;
  margin: 0 auto;

  display: flex;
  color: $primary-text-color;

  @include respond-min($screen-1550px) {
    padding: 0 0 14rem 0;
  }

  @include respond-max($largest-1360px) {
    padding: 0 80px;
  }

  @include respond-max($largest-1000px) {
    padding: 0 20px;
  }

  .client-details {
    background-color: $white;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    // margin: 0rem 2rem;
    border-radius: 10px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    margin-left: 10px;
  }
}
