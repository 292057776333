.reviews__container {
  font-size: 1.6rem;

  .bookings__row {
    flex-wrap: wrap;

    @include respond-max($large-820px) {
      padding: 2rem 1rem;
    }
  }

  .review__text-group {
    @include respond-max($medium-640px) {
      width: 100% !important;
      order: 1 !important;
      flex: none !important;
      margin-top: 2rem !important;
    }
  }

  .bookings__row-group {
    flex: 1;
    max-height: 10rem;
    overflow: hidden;

    &-large {
      flex-basis: 20%;
    }
  }

  .recommended__ratings {
    margin-top: 0;
    img,
    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: $primary-color;
    }
  }

  .review {
    display: flex;
    flex-direction: column;

    &__text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &__actions {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      @include respond-max($medium-640px) {
        justify-content: flex-end;
      }
    }

    &__action-btn {
      width: 4rem;
      height: 4rem;
      cursor: pointer;
    }

    &__action-btn-approve {
      fill: #2ad300;
    }
    &__action-btn-delete {
      fill: red;
    }
  }
}
.review__sort-by {
  align-self: center;
  font-size: 1.6rem;
  font-weight: 600;

  @include respond-max($small-600px) {
    margin-left: 2rem;
  }
}

.review__user-image {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  object-fit: cover;
}

.review__meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
}
.review__name {
  font-size: 1.3rem;
  font-weight: 700;
}
.review__date {
  font-size: 1rem;
}

.review_wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.small-review-tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  padding: 20px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .image {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      object-fit: cover;
    }
  }
  .review-text {
    font-size: 14px;
  }
}
