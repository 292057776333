.recommended__content {
  display: flex;
  flex-direction: column;
  flex: 1.2;
}
.recommended__card {
  display: flex;
  box-shadow: $box-shadow;
  border-radius: 10px;
  padding: 3rem;
  align-self: center;
  width: 100%;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @include respond-max($medium-700px) {
    flex-direction: column;
    gap: 20px;
    padding: 1.5rem;
    // display: block;
    // align-items: center;
    // justify-content: center;
    // padding: 2rem;
  }
}
.recommended-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.recommended__meta-info {
  display: flex;
  cursor: pointer;
  color: $primary-text-color;
  position: relative;

  img {
    @include respond-max($small-540px) {
      margin-right: 1rem;
    }
  }
}

.recommended__meta-love-icon {
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  right: 6.5rem;
  fill: $primary-color;
  padding: 0.5rem 0.8rem;
  background: #f7f7f7f7;
  border-radius: 50%;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
.heart-fill {
  padding: 7px;
  border-radius: 50%;
}
.recommended__meta-share-icon {
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  right: 2rem;
  fill: $primary-color;
  padding: 0rem 0.8rem;
  background: #f7f7f7f7;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}
.recommended_profile {
  border-radius: 50%;
  height: 65px;
  width: 65px;

  @include respond-max($large-820px) {
    height: 45px;
    width: 45px;
  }
}
.recommended__spec {
  font-size: 1.6rem;
  color: #808080;
  font-weight: 500;
}

.text__content {
  padding: 0 2rem;
  width: 100%;



}
.recommended__title {
  font-size: 2.2rem;
  font-weight: 600;
  text-decoration: none;
  color: $primary-text-color;
  @include respond-max($large-820px) {
    font-size: 1.9rem;
  }
}
.recommended__class {
  font-size: 2rem;
  font-weight: 500;
}
.recommended__ratings {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  fill: $primary-color;
  margin-bottom: 10px;
}
.recommended__description {
  margin-bottom: 2rem;
  margin-top: 0.4rem;
  font-size: 1.2rem;
}
.provider__opinion-rating,
.opinion__stars {
  fill: $primary-color;
}
.opinions__number {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
}
.no_review {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;

  margin-top: 5.5rem;
  text-align: center;
  margin-bottom: 5rem;
  img {
    height: 55px;
    width: 55px;

    @include respond-max($small-540px) {
      height: 40px;
      width: 40px;
    }
  }
  h5 {
    font-size: 1.9rem;
    margin-top: 10px;

    @include respond-max($small-540px) {
      font-size: 15px;
    }
  }
  p {
    font-size: 14px;
    @include respond-max($small-540px) {
      font-size: 12px;
    }
  }
}
.opinion__text {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 700;
}

.recommended__location-info {
  padding: 1rem;
}
.recommended__address,
.recommended__slogan,
.recommended__questions {
  display: flex;
  align-items: center;
  margin: 2rem 0;

  .recommended-icon {
    margin-right: 1rem;
  }
}
.recommended__address-text,
.recommended__slogan-text,
.recommended__questions-text {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: $primary-text-color;
}

// * ========================

.recommended__calendar {
  border-left: 1px solid #f7f7f7;
  padding-left: 1rem;
  flex: 2;
  @include respond-max($largest-1200px) {
    flex: 1.2;
  }
  @include respond-max($medium-700px) {
    max-width: 1220px;
    margin: auto;
    border-left: none;
    border-top: 1px solid #f7f7f7;
    padding: 2rem 0;
  }
  @include respond-max($largest-1260px) {
  }

  .nav__bottom-link {
    text-align: center;
    display: block;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .recommended-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 2rem;
  }
}

.recommended__calendar-cols {
  display: flex;
  position: relative;
  justify-content: space-between;
  max-width: 550px;
  margin-left: 15px;

  .recommended-icon {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 0;
    top: 1rem;
  }
  @include respond-max($largest-1200px) {
    max-width: 490px;
  }
  @include respond-max($largest-1100px) {
    max-width: 420px;
  }
  @include respond-max($larger-930px) {
    max-width: 360px;
  }
  @include respond-max($large-820px) {
    max-width: 300px;
  }
  @include respond-max($medium-700px) {
    max-width: 490px;
  }
  @include respond-max($small-600px) {
    max-width: 300px;
  }
  @include respond-max($smaller-470px) {
    max-width: 230px;
  }
}

.recommended__calendar-col {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  span {
    display: block;
  }
}
.calendar__date {
  color: $primary-text-color;
}
.calendar__day {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: center;
}
.calendar__date {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}
.cutout__amount {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2rem;
  text-decoration: line-through;
  color: #d8d8d8;
}
.recommended__time {
  font-size: 1.5rem;
  font-weight: 500;
  color: #535353;
  padding: 8px 1.5rem;
  width: 70px;
  border-radius: 10px;
  background-color: #f9f9f9;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.services {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  gap: 10px;
}
.service-small-tile {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  line-height: 25px;
  li {
    display: inline;
  }
  li::after {
    display: inline;
    content: " " !important;
  }

  @include respond-max($large-820px) {
    font-size: 10px;
    line-height: 20px;
  }
  .pr {
    &::before {
      @include respond-min($largest-1200px) {
        content: "\a";
        display: block;
      }
    }
  }
}
.services-name-price {
  font-size: 1.5rem;

  color: black;
  align-items: center;
  // display: flex;
  // margin-bottom: 2px;
  font-weight: 600;
  @include respond-max($large-820px) {
    font-size: 10px;
  }

  span {
    // font-size: 1.3rem;
    font-weight: 500;

    @include respond-max($large-820px) {
      font-size: 8px;
    }
  }
}
.book-button {
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0px 1.4rem;
  background-image: $button-background;
  color: white;
  border-radius: 7px;
  height: 28px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;

  @include respond-max($large-820px) {
    height: 20px;
    padding: 0px 1rem;
    border-radius: 5px;
    font-size: 1rem;
  }
}
.full-stop {
  font-size: 16px;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 0;

  &:before {
    display: inline-block !important;
    content: " ";
  }

  @include respond-max($large-820px) {
    font-size: 12px;
  }
}
.free__slot {
  cursor: pointer;
}
.show_more {
  color: $primary-color;
  // margin-left: 1rem;
  font-size: 1.5rem;
  // text-align: center;
  margin-top: 2rem;
  cursor: pointer;
}
