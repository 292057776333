@mixin date-text($selected, $close, $width, $padding) {
  text-align: center;
  // padding: $padding;
  width: width;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  @if $close {
    color: rgba(0, 0, 0, 0.17);
  }
  // @if $selected {
  //   background-color: $primary-color;
  //   color: white;
  //   &:hover {
  //     background-color: $primary-color;
  //     color: white;
  //   }
  // }
  .box {
    height: 35px;
    width: 35px;
    margin: auto;
    padding-top: 8px;
    border-radius: 100%;
    font-size: 13px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.033);
      color: black;
    }
    @if $selected {
      background-color: $primary-color;
      color: white;
      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
  }
}

.custom_calender {
  // position: absolute;
  // z-index: 999999999;
  // margin-top: 0.3rem;
  .top_row {
    display: flex;
    padding: 0 10px 0 10px;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .pre_next_icon {
    text-decoration: none;
    display: flex;
    height: 40px;
    width: 40px;
    padding-bottom: 5px;
    padding-right: 3px;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $black;
    font-size: 4rem;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.033);
    }
  }
  .calender_box {
    background-color: white;
    width: 100% !important;
    border: none !important;

    @include respond-min($medium-760px) {
      width: 300px !important;
      padding-bottom: 10px;
    }

    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .calender_table {
    width: 100%;
    // padding: 0 0 10px 0;
  }
  .month_text {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .unselected_date_text {
    @include date-text(false, false, 40px, 5px 0);
  }
  .selected_date_text {
    @include date-text(true, false, 40px, 5px 0);
  }
  .close_date_text {
    @include date-text(false, true, 40px, 5px 0);
  }
  .dot_box {
    display: flex;
    flex-direction: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .dot {
      width: 5px;
      height: 5px;
      // margin: 1px;
      margin-top: 5px;
      border-radius: 50%;
      background-color: $primary-color;
      // margin-right: 5px;
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .trans {
      background-color: transparent;
    }
  }
}

.load_opacity {
  opacity: 0.5;
}
