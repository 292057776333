.business__home {
  margin-top: 18rem;

  @include respond-max($largest-1000px) {
    margin-top: 20rem;
  }

  .business__services {
    padding: 8rem 17rem;
    display: flex;

    @include respond-max($largest-1260px) {
      padding: 2rem 17rem;
    }
    @include respond-max($large-800px) {
      padding: 2rem 6rem;
    }

    @include respond-max($small-600px) {
      padding: 2rem 3rem;
    }

    @include respond-max($medium-640px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .business__services-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;

    @include respond-max($medium-640px) {
      flex: none;
      width: 40%;

      &:first-child {
        margin-bottom: 4rem;
      }

      &:first-child,
      &:nth-child(3) {
        margin-right: 2rem !important;
      }
    }

    & > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:not(:last-child) {
      margin-right: 4rem;

      @include respond-max($medium-640px) {
        margin-right: 0;
      }
    }
  }

  .service__card-image {
    width: 14rem;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #000852, #000330);

    img {
      width: 75%;
    }
  }

  .service__card-desc {
    font-size: 1.2rem;
    color: $primary-text-color;
  }
}
