.main-business {
  margin-left: auto;
  transition: all 0.4s;
  margin-top: 15rem;
  margin-bottom: 10rem;
  margin-right: 1rem;
  width: calc(100% - 32rem);

  @include respond-max($larger-900px) {
    width: calc(100% - 32rem);
  }

  @include respond-max($medium-640px) {
    width: calc(100% - 32rem);
  }

  @include respond-max($smaller-440px) {
    width: calc(100% - 22rem);
    margin-left: auto;
    margin-bottom: 0;
  }

  & > * {
    width: 100%;
  }

  &-transformed {
    transform: translateX(40rem);
    margin-left: 2rem;
  }

  .bookings {
    width: 100%;
  }
  .bookings__container,
  .bookings {
    width: 100%;
    margin: 0;

    @include respond-max($largest-1100px) {
      padding: 0;
    }
  }

  .opts-icon {
    margin-left: auto;
    width: 3rem;
  }

  .bookings__row-group,
  .bookings__filter {
    font-size: inherit;
  }

  .display__indicator {
    @include respond-max($medium-640px) {
      width: 100%;
      text-align: center;
      padding: 1rem 0 2rem 0;
    }
  }
  .paging {
    @include respond-max($medium-640px) {
      width: 100%;
      justify-content: space-around;
    }
  }

  .chat__container {
    width: 100%;
  }
}
