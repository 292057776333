.info {
  display: flex;
  // padding: 5rem 22rem 5rem 22rem;

  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 6rem 0;

  @include respond-max($largest-1260px) {
    padding: 2rem 0 6rem 0;
  }

  @include respond-max($larger-930px) {
    padding-bottom: 10rem;
  }

  @include respond-max($small-600px) {
    padding-bottom: 6rem;
  }
  // white-space: pre-line;

  // @include respond-max($large-800px) {
  //   padding: 2rem 0 6rem 0;
  // }

  // @include respond-max($small-600px) {
  //   padding: 2rem 0 6rem 0;
  // }

  &__slogan {
    font-size: 3.5rem;
    color: $primary-text-color;
    font-weight: 500;
  }

  &__subheader {
    font-size: 2rem;
    color: $primary-text-color;
    font-weight: 400;
    margin-top: 10px;
  }

  &__para {
    color: $primary-text-color;
    font-size: 1.6rem;
    font-weight: 300;
    margin-top: 15px;
  }

  .info-cover-img {
    // margin-left: 100px;
    width: 700px;
    @include respond-max($extra-large-1460px) {
      width: 600px;
    }
    @include respond-max($largest-1100px) {
      width: 500px;
    }
    @include respond-max($larger-930px) {
      position: absolute;
      z-index: -1;
      opacity: 0.3;
      width: 500px;
      top: 5rem;
      right: 5rem;
      margin-left: 0px;
    }
    @include respond-max($small-600px) {
      top: 5rem;
      right: 0rem;
      width: 350px;
    }
  }
}
