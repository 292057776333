.arrow_wrapper {
  background-color: rgba(0, 0, 0, 0.187);
  border-radius: 100%;
  height: 20px;
  width: 20px;
  .arrow_right {
    border-width: 0 2px 2px 0;
    padding: 3px;
    margin-left: 4px;
    margin-top: 5.5px;
  }
  .arrow_left {
    border-width: 0 2px 2px 0;
    padding: 3px;
    margin-left: 6.6px;
    margin-top: 5.9px;
  }
}
.booking-preview-modal {
  @include appmodal(53vh, 40vw);
  @include respond-max($medium-700px) {
    @include appmodal(85vh, 85vw);
  }
  @include respond-max($small-600px) {
    @include appmodal(85vh, 85vw);
  }

  .content-section {
    margin-top: 40px;
    padding: 20px;
    gap: 20px;

    .profile_wrapper {
      align-items: center;
      justify-content: space-between;
      img {
        height: 70px;
        width: 70px;
        border-radius: 100px;
      }
      .status-container {
        font-size: 12px;
        padding: 3px;
        color: $white;
        background-color: $primary-color;
        border-radius: 50px;
        text-align: center;
        max-width: 80px;
        margin-top: 5px;
      }
      .calender-ic {
        background-color: $primary-color;
        padding: 10px;
        border-radius: 10px;
        .ic {
          height: 20px;
          width: 20px;
          margin-top: 0px;
        }
        cursor: pointer;
      }
    }

    label {
      font-size: 16px;
      font-weight: bold;
    }
    span {
      font-size: 14px;
    }

    .button-wrapper {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      justify-content: center;

      .primary__btn {
        border-radius: 10px;
        width: 49%;
      }
      @include respond-max($smaller-470px) {
        flex-direction: column;
        .primary__btn {
          width: 100%;
        }
      }
    }
  }
}
.add-review-modal {
  @include appmodal(420px, 30vw);
  @include respond-max($medium-700px) {
    @include appmodal(420px, 80vw);
  }

  .content-section {
    margin: 10px;
    color: $black;
    align-items: center;
    .heading {
      font-size: 20px;
      font-weight: bold;
    }
    .desc {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
    .fa-2x {
      font-size: 3em !important;
      color: rgb(247, 219, 6) !important;
    }
    textarea {
      width: 100%;
      padding: 12px;
      border-radius: 10px;
    }
  }
}
