.primary__btn {
  padding: 1rem 1.5rem;
  background-color: $primary-color;
  background-image: $button-background;

  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;

  &:disabled {
    background-color: $button-disabled-color;
  }
}
.buiness-button {
  background-image: linear-gradient(
    -29deg,
    rgb(65, 99, 209) 0%,
    rgb(35, 59, 136) 100%
  );
}
.clients-button {
  background-image: linear-gradient(
    -29deg,
    rgb(14, 135, 127) 0%,
    rgb(10, 197, 184) 100%
  );
}

.select__btn {
  padding: 1rem 2rem;
  border: 1.07px solid $primary-text-color;
  color: $primary-text-color;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
