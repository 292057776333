.registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 22rem 0;
  color: $primary-text-color;

  // @include respond-max($largest-1000px) {
  //   margin: 30rem 0;
  // }

  .divider {
    width: 40%;
    &__text {
      font-size: 1.4rem;
      top: -1.6rem;
    }
  }
}
.alert-text {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
  text-align: center;
  padding-bottom: 1rem;
}
.primary__input {
  border: 1px solid #d8d8d8;
  padding: 0.2rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  // width: 38rem;
  overflow: hidden;
  margin: 5px 0 1rem 0;
  @include respond-min($medium-700px) {
    width: 38rem;
  }
  &:focus-within {
    //border: 1px solid $primary-color;
  }

  .phone-identity-select {
    //margin-right: 1rem;
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5rem;
    -webkit-appearance: none;
    color: $primary-text-color;
  }
}
.primary__input-content {
  border: none;
  font-size: 14px;
  color: $primary-text-color;
  padding: 0 2rem;
  width: 100%;
  appearance: none;
}
.primary-label {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
  color: $primary-text-color;
  margin-bottom: 5px;
}
.primary__btn {
  align-self: center;
  width: 19.9rem;

  @include respond-max($largest-1100px) {
    width: 22rem;
  }
}

.business__btn {
  background-image: linear-gradient(
    -29deg,
    rgb(65, 99, 209) 0%,
    rgb(35, 59, 136) 100%
  ) !important;
  margin-bottom: 10px;
}

.dial-icon {
  height: 3rem;
  width: 3rem;
  margin-left: 5px;
}

.social__btn-stack {
  display: flex;
  flex-direction: column;
  padding: 4rem 0;

  .primary__btn {
    display: flex;
    align-items: center;
    width: 40rem;
    justify-content: flex-start;
    font-size: 1.7rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    img {
      width: 3rem;
      height: 3rem;
      margin-right: 2rem;
    }
  }

  .facebook-btn {
    background-color: #4267b2;
  }
  .google-btn {
    background-color: transparent;
    border: 1px solid $primary-text-color;
  }
}

.forgot-password-link {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  color: #d8d8d8;
  text-decoration: none;
}

.invalid__verification {
  color: red;
  width: 100%;
  font-size: 1.3rem;
}
input:focus-visible {
  outline: none;
}
.button__my-2 {
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}
