.primary-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.auth-form {
  .forgot {
    font-size: 12px;
    cursor: pointer;
  }
  .login-with {
    font-size: 14px;
    padding-top: 15px;
  }
  .primary__btn {
    font-size: 15px;
    width: 250px;
    height: 35px;
  }
  .auth-social-icon {
    height: 45px;
    width: 45px;
    border: 1px solid rgb(215, 215, 215);
    background-color: $white;
    border-radius: 100%;
    padding: 7px;
    cursor: pointer;
    margin: 15px 0 0 0;
  }
  .primary__input {
    margin-bottom: 2rem;
  }
}
