.recommended-section {
  max-width: $app-max-width;
  margin: 5rem auto;
  padding: 0.2rem 0 0.2rem 0;

  // padding: 2rem 17rem 10rem 17rem;
  // max-height: 142rem;
  // overflow-y: auto;
  // @include respond-max($largest-1360px) {
  //   padding: 0.2rem 0 5rem 0;
  // }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cecece;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  // @include respond-max($medium-670px) {
  //   padding: 10rem 2rem;
  // }
  // @include respond-max($smaller-470px) {
  //   padding: 15rem 2rem;
  // }
}
.recommended {
  display: flex;
  flex-direction: column;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid $primary-color !important;
  margin-left: -5px !important;
}
.carousel.carousel-slider .control-arrow {
  height: 0;
  padding: 0;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid $primary-color !important;
}
.carousel-status,
.control-dots {
  display: none;
}
.carousel.carousel-slider .control-arrow:hover {
  background: unset !important;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  width: 600px;
  @include respond-max($largest-1200px) {
    max-width: 550px;
  }
  @include respond-max($largest-1100px) {
    max-width: 480px;
  }
  @include respond-max($larger-930px) {
    max-width: 420px;
  }
  @include respond-max($large-820px) {
    max-width: 360px;
  }
  @include respond-max($medium-700px) {
    max-width: 550px;
  }
  @include respond-max($small-600px) {
    max-width: 350px;
  }
  @include respond-max($smaller-470px) {
    max-width: 300px;
  }
  // @include respond-max($large-850px) {
  //   max-width: 450px;
  // }
  // @include respond-max($medium-750px) {
  //   max-width: 350px;
  // }
}

.carousel-root {
  max-width: 616px;
  margin: auto;
  @include respond-max($largest-1200px) {
    max-width: 550px;
  }
  @include respond-max($largest-1100px) {
    max-width: 480px;
  }
  @include respond-max($larger-930px) {
    max-width: 420px;
  }
  @include respond-max($large-820px) {
    max-width: 360px;
  }
  @include respond-max($medium-700px) {
    max-width: 550px;
  }
  @include respond-max($small-600px) {
    max-width: 350px;
  }
  @include respond-max($smaller-470px) {
    max-width: 300px;
  }
  // @include respond-max($medium-750px) {
  //   max-width: 350px;
  // }
}

.carousel .slider-wrapper {
  // margin-left: 15px !important;
}
