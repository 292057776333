.client-account-form-section {
  padding: 5rem 2rem;
  display: flex;

  @include respond-min($screen-1550px) {
    padding: 5rem 0;
  }

  @include respond-max($largest-1200px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .primary-form {
    align-items: flex-start;
    padding: 0 5rem;

    @include respond-max($largest-1200px) {
      align-items: center;
    }

    .primary__btn {
      align-self: flex-start;
      margin-top: auto;

      @include respond-max($largest-1200px) {
        align-self: center;
        margin-top: 0;
      }
    }
  }
}

.address-sections {
  box-shadow: $box-shadow;
  padding: 2rem;
  border-radius: 10px;
  overflow-x: auto;
  width: 68vw;
  margin-left: 2rem;
}

.address-text {
  font-size: 20px;
  color: black;
  // display: block;
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: inline-block;
  width: 1000px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex-justify-between {
  display: flex;
  justify-content: space-between;
}
.save-edit-icon {
  margin-top: auto;
  margin-bottom: auto;
}
.input-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  
}
.input-section textarea {
  resize: none;
  margin-right: 2rem;
  width: 100%;
  padding: 1rem;
  font-size: 16px;
}
.input-section textarea:focus-visible {
  outline: none;
}
.mt-4 {
  margin-top: 4rem;
}
.pointer {
  cursor: pointer;
}
.save-button {
  width: 10rem!important;
}
.edit-icon {
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
}