.image-preview {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  &-content {
    position: relative;
    width: 600px;
    height: 500px;
    @include respond-max($larger-900px) {
      width: 550px;
      height: 500px;
    }
    @include respond-max($small-600px) {
      width: 350px;
      height: 350px;
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
.image-preview-close {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.453);
  height: 30px;
  width: 30px;
  color: $white;
  font-size: 25px;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  text-align: center;
  line-height: 0;
  padding-top: 15px;
  cursor: pointer;
}
.arrow-wrapper-right {
  position: absolute;
  top: 46%;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.453);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 13px;
  padding-right: 4px;
  cursor: pointer;
}
.arrow-wrapper-left {
  position: absolute;
  top: 46%;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.453);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 13px;
  padding-left: 4px;
  cursor: pointer;
}

.arrow_right {
  border: solid $white;
  border-width: 0 3.5px 3.5px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow_left {
  border: solid $white;
  border-width: 0 3.5px 3.5px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
