.user__nav {
  min-width: 29rem;
  box-shadow: $box-shadow;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  @include respond-max($largest-1200px) {
    position: fixed;
    background-color: #fff;
    z-index: 900;
    top: 14rem;
    left: 0;
    height: 100%;
    border-radius: 0;
    transform: translateX(-100%);
    transition: all 0.4s;
    min-width: 37rem;

    &.opened {
      transform: translateX(0);
      -webkit-box-shadow: 10px 40px 83px -67px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 40px 83px -67px rgba(0, 0, 0, 0.75);
      box-shadow: 10px 40px 83px -67px rgba(0, 0, 0, 0.75);
    }
  }

  @include respond-max($larger-900px) {
    top: 8.2rem;
  }
  @include respond-max($large-820px) {
    top: 13.6rem;
  }

  .user__account-edit-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
  }

  .user__meta-info {
    position: relative;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1.07px solid #d8d8d8;

    @include respond-max($larger-900px) {
      padding: 1rem;
    }

    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  .user__profile-image {
    width: 15rem;
    height: 15em;
    object-fit: cover;
  }
  .user__name {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .user__registration-date {
    font-size: 1.4rem;
    color: $primary-color;
  }

  &-items {
    display: flex;
    flex-direction: column;
  }
  &-item {
    width: 100%;
    list-style-type: none;
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    cursor: pointer;

    img,
    svg {
      height: 3rem;
      width: 3rem;
      margin-right: 1rem;
      fill: $primary-text-color;
    }

    &:not(:last-child) {
      border-bottom: 1.07px solid #d8d8d8;
    }
  }
  &-item-active {
    color: $primary-color;
    svg {
      fill: $primary-color;
    }
  }

  &-opener {
    height: 4rem;
    width: 4rem;
    display: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid #d8d8d8;
    border-left: none;
    position: absolute;
    top: 0;
    z-index: 9000;
    right: -4.1rem;
    background-color: white;

    img {
      width: 100%;
    }

    @include respond-min($largest-1200px) {
      display: none;
    }
    @include respond-max($larger-900px) {
      height: 6rem;
      width: 6rem;
      border: none;
      right: -7.1rem;
      top: 8px;
    }
  }

  &-opener.displayed {
    display: block;
    @include respond-max($medium-730px) {
      display: block;
    }
  }
}
