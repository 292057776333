.search__results-section {
  padding: 0 17rem;

  @include respond-max($large-800px) {
    padding: 0 6rem;
  }

  @include respond-max($small-600px) {
    padding: 0 3rem;
  }

  .search__stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2rem 0;

    .select__form-group {
      width: 30rem;
      @include respond-max($smaller-470px) {
        margin-top: 2rem;
        width: 100%;
        order: 1;
      }
    }
  }

  .paging {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 10rem;
    font-size: 1.6rem;

    .current__page {
      border: 1px solid $primary-color;
      padding: 2px 1rem;
      border-radius: 5px;
    }
  }
}
