.service-provider-section {
  // padding: 5rem 17rem;
  // padding: 0rem 17rem 5rem 17rem;
  color: $primary-text-color;

  max-width: $app-max-width;
  margin: auto;
  // margin-top: 20rem;
  margin-bottom: 5rem;
  // @include respond-min($screen-1550px) {
  //   // padding: 5rem 17rem;
  //   padding: 0rem 17rem 5rem 17rem;
  // }
  @include respond-max($largest-1360px) {
    padding: 0 80px;
  }

  @include respond-max($largest-1000px) {
    padding: 0 20px;
    // margin-top: 35rem;
  }

  .share__profiler {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 2rem;
    color: $primary-color;
    margin-left: auto;

    img {
      width: 3rem;
    }
  }

  .provider {
    box-shadow: $box-shadow;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 2rem;
    &-header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f7f7f7;
      padding: 2rem;
      justify-content: space-between;

      .select {
        padding: 5px 12rem 5px 5px;
      }

      &-icon {
        width: 3rem;
      }

      .select {
        @include respond-max($small-540px) {
          padding: 5px 2rem 5px 5px;
        }
      }
    }

    &-bottom {
      margin-top: auto;
    }

    &-main-heading {
      text-align: left;
      font-size: 2.3rem;
      flex: 1;
    }

    &-heading {
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-align: left;
      color: $primary-color;
      text-transform: capitalize;
      font-weight: 500;
    }
    &-info {
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: left;
      list-style-type: none;
    }

    &__cols {
      display: flex;
      margin-bottom: 2rem;
      @include respond-max($largest-1000px) {
        flex-direction: column;
      }
    }
    &__col {
      flex: 1;
      // max-width: 67rem;
      @include respond-max($largest-1000px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .details__cols {
    display: flex;
    padding: 2rem;

    @include respond-max($medium-670px) {
      flex-direction: column;
    }
  }
  .details__col {
    // padding: 0 2rem;
    // flex: 1;
    padding-right: 2rem;
    @include respond-max($medium-670px) {
      padding-bottom: 2rem;
    }
    &:last-child {
      border-left: 1px solid #f7f7f7;
      padding-left: 2rem;
      @include respond-max($medium-670px) {
        border-left: none;
        padding-top: 2rem;
        padding-left: 0;
        border-top: 1px solid #f7f7f7;
      }
    }
  }

  .portfolio__provider {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #f7f7f7;

    @include respond-max($largest-1000px) {
      border-bottom: 1px solid #f7f7f7;
    }
    .portfolio {
      padding: 2rem;

      &__heading {
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-weight: 500;
      }

      &__date {
        font-size: 1.5rem;
        color: #878686;
        font-weight: 400;
      }

      &__images {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        width: 100%;
        gap: 10px;
        @include respond-max($small-600px) {
          gap: 5px;
        }
        img {
          width: 18%;
          height: 10rem;
          object-fit: cover;
          // flex: 1;

          @include respond-max($small-600px) {
            height: 12rem;
            width: 32%;
          }
        }
      }
    }
  }

  .chat__box {
    flex: 1;
    .chatroom__body {
      height: 35vh;
    }

    &-text {
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem 0;

      img {
        width: 20rem;
      }
    }
  }

  .cta {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: $primary-color;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;

    &__text {
      font-size: 2.3rem;
      font-weight: 500;
      line-height: 2.8rem;
      color: white;
    }
    &__input {
      width: 100%;
      background-color: $primary-color;
      border: none;
      outline: none;
      font-size: 2.3rem;
      font-weight: 500;
      line-height: 2.8rem;
      color: white;
    }
    &__input::placeholder {
      color: white;
    }

    img {
      width: 2.5rem;
      margin-left: auto;
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }

  .provider__opinions {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #f7f7f7;
    padding-top: 1rem;
    height: 85%;

    .primary__btn {
      align-self: flex-start;
      margin-left: 2rem;
      margin-top: 3rem;
      margin: 2rem 0 2rem 3rem;
    }
  }
  .opinion__stars {
    display: flex;
    align-items: center;
    .opinions__number {
      margin-left: 10px;
    }
    // @include respond-max($largest-1260px) {
    //   display: none;
    // }
  }
  .provider__opinion {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;

    & > *:not(:last-child) {
      margin-bottom: 5px;
    }

    &-rating {
      display: flex;
      align-items: center;

      .opinions__number {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.8rem;
      }

      .recommended-icon {
        width: 2rem;
        height: 2rem;
      }
    }

    &-text {
      font-size: 1.8rem;
      font-style: italic;
      font-weight: 400;
      line-height: 2.2rem;
      color: $primary-text-color;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-date {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.8rem;
      color: #878686;
    }
  }
}
.svg-icons {
  fill: $primary-color;
}
