$primary-text-color: #535353;
$primary-font-stack: "Poppins", sans-serif;
$box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
:root {
  --primary-color: #0ac5b8;
  --button-disabled-color: #3b9e487e;
  --button-background: linear-gradient(
    -29deg,
    rgb(14, 135, 127) 0%,
    rgb(10, 197, 184) 100%
  );
}

$primary-color: var(--primary-color);
$button-disabled-color: var(--button-disabled-color);
$button-background: var(--button-background);
$black: black;
$white: white;
$grey: #535353;
$hover-color: #233a8822;
// ! react calendar library variables

$event-bg: #233b88;
$event-padding: 8px;

$app-max-width: 1300px;

// ! responsive breakpoints

// ? min breakpoints
$screen-1900px: 118.75em;
$screen-1550px: 96.875em;
$screen-1525px: 95.3125em;

// ? max breakpoints
$extra-large-1540px: 96.25em;
$extra-large-1460px: 91.25em;
$largest-1360px: 90em;
$largest-1260px: 78.75em;
$largest-1200px: 75em;
$largest-1100px: 68.75em;
$largest-1000px: 62.5em;
$larger-930px: 58.125em;
$larger-900px: 56.25em;
$large-820px: 51.25em;
$large-850px: 53.125em;
$large-800px: 50em;
$medium-760px: 47.5em;
$medium-750px: 46.875em;
$medium-730px: 45.625em;
$medium-700px: 43.75em;
$medium-670px: 41.875em;
$medium-640px: 40em;
$small-600px: 37.5em;
$small-540px: 33.75em;
$smaller-470px: 29.375em;
$smaller-440px: 27.5em;
$smaller-410px: 25.625em;
