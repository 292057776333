@mixin appmodal($height, $width) {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  &-content {
    width: $width;
    height: $height;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;

    overflow-y: auto;
    overflow-x: hidden;
  }
  &-close-icon {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.modal {
  @include appmodal(60rem, 60rem);

  @include respond-max($small-600px) {
    @include appmodal(85vh, 85vw);
  }
}

.share-modal {
  @include appmodal(auto, 360px);

  @include respond-max($small-600px) {
    @include appmodal(auto, 300px);
  }
  .share-modal-close-icon {
    height: 1.5rem;
    width: 1.5rem;
    top: 1.5rem;
    right: 1.5rem;
  }
  .share-body {
    padding: 20px;
  }
  .copy-link {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    border: 1px solid $primary-color;
    padding: 0 10px;
    border-radius: 5px;
    span {
      color: $black;
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }
    button {
      font-size: 12px;
      height: 30px;
      background-color: transparent;
      color: $primary-color;
    }
  }
  .button-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 12px 0;
  }
}