.subscription-section {
  .secondary-heading {
    text-align: left;
    font-weight: 700;
    margin: 0 0 4rem 6rem;
    font-size: 4rem;

    @include respond-max($largest-1000px) {
      text-align: center;
      margin: 0 0 4rem 0;
    }
  }

  .sub__cards {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-wrap: wrap;

    .sub__card {
      display: flex;
      flex-direction: column;
      padding-top: 3rem;
      border-radius: 2rem;
      width: 35rem;
      height: 50rem;
      overflow: hidden;
      text-align: center;
      color: $primary-text-color;
      background-color: #fff;

      @include respond-max($largest-1000px) {
        height: 40rem;
        &:last-child {
          margin-top: 3rem;
        }
      }

      -webkit-box-shadow: -1px 6px 21px -15px #000000bf;
      -moz-box-shadow: -1px 6px 21px -15px #000000bf;
      box-shadow: -1px 6px 21px -15px #000000bf;
      &-large {
        height: 60rem;
        @include respond-max($largest-1000px) {
          height: 50rem;
        }
      }

      &-type,
      &-price,
      &-services {
        padding: 1rem 3rem;
      }

      &-type {
        font-size: 1.6rem;
        font-weight: 600;
      }
      &-price {
        font-size: 7rem;
        font-weight: 900;
      }
      &-services {
        padding-top: 4rem;
      }
      &-service {
        font-size: 1.6rem;
      }
      &-cta {
        margin-top: auto;
        padding: 2rem;
        cursor: pointer;
      }
      &-cta.premium {
        background-color: #00b7ff;
      }
    }
  }

  .plan__selector {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: #f8f8f8;
    border-radius: 10px;
    width: 60rem;
    margin: 10rem 0 8rem 6rem;
    height: 30rem;
    color: $primary-text-color;
    position: relative;

    @include respond-max($largest-1000px) {
      width: auto;
      margin-left: 0;
    }

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .plan__type {
      border-top: 1px solid #d8d8d8;
      padding: 2rem;
      & > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    .plan__note {
      font-size: 1.3rem;
      text-align: center;
    }

    .primary__btn {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
    }

    #selector-plan {
      @include respond-max($small-600px) {
        font-size: 1.2rem;
      }
    }
  }
}
