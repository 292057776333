.dashboard-section {
  padding: 1rem;
  border-radius: 10px;
  background-color: #fafafa;
  margin-top: 3rem;

  & > .secondary-heading {
    text-align: left;
    margin-bottom: 2rem;
  }

  .dashboard__cards {
    display: flex;
    align-items: center;

    @include respond-max($medium-700px) {
      flex-wrap: wrap;
    }
  }

  .dashboard__card {
    display: flex;
    align-items: center;
    padding: 2rem 4rem;
    border-radius: 10px;
    flex: 1;
    height: 17rem;

    @include respond-max($medium-700px) {
      flex: none;
      width: 48%;

      &:first-child,
      &:nth-child(2) {
        margin-bottom: 1rem;
      }

      &:first-child,
      &:nth-child(3) {
        margin-right: 1rem;
      }

      &:nth-child(2) {
        margin-right: 0 !important;
      }
    }

    @include respond-max($largest-1000px) {
      padding: 2rem;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &-icon {
      width: 6rem;
      fill: white;
      margin-left: auto;
    }
    &-amount,
    &-title {
      display: block;
      color: white;
      font-weight: 600;
    }
    &-amount {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    &-title {
      font-size: 1.6rem;
    }
  }

  .dashboard__card.blue {
    background: linear-gradient(to right, #00b7ff, #68d4ff);
  }
  .dashboard__card.green {
    background: linear-gradient(to right, #00ff4c, #48ff7f);
  }
  .dashboard__card.orange {
    background: linear-gradient(to right, #ffa500, #ffc23e);
  }
  .dashboard__card.red {
    background: linear-gradient(to right, #ff3333, #ff6262);
  }

  .dashboard__stats {
    display: flex;
    @include respond-max($large-850px) {
      flex-direction: column;
    }
  }

  .dashboard__booking-stats {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    @include respond-max($large-850px) {
      flex-direction: row;
    }

    .tertiary-heading {
      font-size: 1.5rem;
    }
  }

  .dashboard__pie-stats {
    width: 25rem;
    padding: 1rem;
    background-color: #fff;
    border-radius: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;

    @include respond-max($large-850px) {
      &:last-child {
        margin-bottom: 1rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .pie__container {
      width: 65%;
      align-self: center;
    }
  }

  .dashboard__reservation-stats {
    flex: 1;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    // height: 50rem;
  }

  line {
    stroke: transparent !important;
  }

  .reservation__header {
    display: flex;
    padding: 3rem;

    .tertiary-heading {
      margin-right: 3rem;
      span {
        font-size: 1.2rem;
        color: #d8d8d8;
      }
    }
  }
  .reservation__title {
    margin-right: auto;

    p {
      margin-top: 5px;
      color: #d8d8d8;
    }
  }
  .reservation__opts-icon {
    width: 2rem;
    rotate: 90deg;
    margin-top: -2rem;
  }
  .reservation__graph-container {
    width: 100%;
    height: 75%;
  }

  .bar__stats {
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .bar__indicators {
    display: flex;
  }
  .bar__indicator {
    display: flex;
    flex-direction: column;
    flex: 1;

    &-text {
      font-size: 1rem;
      display: flex;
      align-items: center;
    }
    &-number {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .indicator__icon {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      margin-right: 5px;

      &.blue {
        background: linear-gradient(to right, #00b7ff, #68d4ff);
      }
      &.green {
        background: linear-gradient(to right, #00ff4c, #48ff7f);
      }
      &.orange {
        background: linear-gradient(to right, #ffa500, #ffc23e);
      }
    }
  }

  .check__stats {
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 20rem;
    flex: 1;

    .pie__container {
      width: 45%;
    }

    .check__stat {
      display: flex;
      align-items: center;
      flex: 1;

      &-text {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $primary-text-color;
      }
      .check__percent {
        font-size: 1.6rem;
        font-weight: 600;
      }
      .check__type {
        font-size: 1.3rem;
        color: gray;
      }
    }
  }

  .stats__row {
    display: flex;

    @include respond-max($largest-1000px) {
      flex-direction: column;
    }

    .dashboard__col {
      flex: 1;
      margin-right: 1rem;
      border-radius: 10px;
      overflow: hidden;
      @include respond-max($largest-1000px) {
        margin-right: 0;
        &:last-child {
          margin-top: 1rem;
        }
      }
    }

    .react-calendar {
      border: none;
      width: 100%;
      padding: 2rem 4rem;

      button {
        color: $primary-text-color;

        &:hover {
          background: transparent;
        }
      }
    }

    .react-calendar__tile--active {
      background: $primary-color;
      border-radius: 2rem;
      color: #fff !important;

      &:hover {
        background: $primary-color !important;
      }
    }
    .react-calendar__tile {
      padding: 2rem;
      color: $primary-text-color;

      &:hover {
        background: transparent;
      }
    }

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
      display: none;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: #d8d8d8 !important;
    }
  }
  .calendar__bookings {
    padding: 2rem;
    background-color: #fff;

    &-header {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      padding: 0 3rem;

      .tertiary-heading {
        margin-right: auto;
      }
    }
    &-link {
      color: #00b7ff;
    }
    &-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-card {
      width: 49%;
      display: flex;
      align-items: flex-start;
      padding: 2rem;
      border: 0.5px solid #e7e7e7;
      border-radius: 10px;
      margin-bottom: 1rem;

      @include respond-max($medium-640px) {
        padding: 1rem;
      }

      img {
        width: 5rem;
        height: 5rem;
        border-radius: 10px;
        margin-right: 2rem;
      }

      .user__meta-info {
        color: $primary-text-color;

        & > *:not(:last-child) {
          margin-bottom: 1rem;
        }

        .user__meta-name {
          font-size: 1.4rem;
        }
        .room__info {
          font-size: 1.2rem;
          color: #d8d8d8;
        }

        span {
          display: block;
        }
      }
    }
  }

  .dashboard__opinions {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    overflow: hidden;

    .calendar__bookings-header {
      img {
        margin-top: 1rem;
      }
    }
  }

  .bookings {
    border: none;

    &__row {
      font-size: 1rem;

      &-group {
        padding: 0;
        align-items: flex-start;

        @include respond-max($medium-640px) {
          flex: none;
          width: 50%;
        }
      }

      .review__date {
        font-size: 7px;
      }
      .review__user-image {
        margin-right: 7px !important;
        width: 4rem;
        height: 4rem;
        border-radius: 10px;
      }
      .review__text-group {
        flex-basis: 15%;
      }
    }
  }
}
