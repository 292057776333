.loader {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid $primary-color;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 5rem auto;

  @include respond-max($larger-900px) {
    width: 30px;
    height: 30px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid $primary-color;
  }
  @include respond-max($smaller-440px) {
    width: 20px;
    height: 20px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid $primary-color;
  }
}
.full_screen_top {
  margin-top: 30rem;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
