.calender__modal {
  height: auto !important;
}
.react-calendar {
  width: 100% !important;
  border: none !important;

  @include respond-min($medium-760px) {
    width: 300px !important;
    padding-bottom: 10px;
  }
}
.react-calendar__tile--active {
  background-color: $primary-color !important;
  color: black !important;
  // flex-basis: 20% !important;
  // max-width: 20% !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #757575 !important;
}

.react-calendar__tile--now {
  background-color: $primary-color !important;
  color: white !important;
}
.react-calendar__month-view__days__day {
  // flex: 0 0 5.5% !important;
  // border-radius: 50% !important;
}

.react-calendar button {
  // margin: 10px !important;
  // margin-left: 3px !important;
  // margin-right: 3px !important;
  // margin: 0 !important;

  margin-top: 2px !important;
  margin-bottom: 2px !important;
  font-size: 16px !important;
  @include respond-max($medium-700px) {
    font-size: 14px !important;
  }

  color: $black;
  // padding: 22px 0 !important;
  @include respond-min($smaller-470px) {
    // padding: 22px 0 !important;
  }
}
.react-calendar__tile:disabled,
.react-calendar__navigation button:disabled,
.react-calendar__navigation__label {
  background: none !important;
  color: $grey;
}
.react-calendar__navigation__arrow {
  display: none !important;
}

.react-calendar__navigation button:enabled:hover {
  height: 42px !important;
}
.react-calendar__navigation__label {
  // margin-left: 22px !important;
  color: $black;
}
.react-calendar__navigation__label__labelText {
  margin: 12px 5px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
}
.react-calendar__month-view__weekdays__weekday {
  // flex: 0 0 14.4% !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  border-bottom: none !important;
  text-transform: capitalize !important;
  color: black !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  abbr[title] {
    text-decoration: none !important;
  }
}
.react-calendar__month-view__days__day {
  // flex: 0 0 14.4% !important;
  // @include respond-max($smaller-470px) {
  border-radius: 100%;
  // height: 20px;
  // width: 20px !important;

  // }
  abbr[aria-label] {
    text-decoration: none !important;
    // color: green;
  }
}
// .react-calendar__month-view__weekdays {
//   width: 98.3% !important;
// }
.react-calendar__navigation button {
  text-align: start !important;
}
.section {
  padding: 10px 10px;
  width: 100%;


  @include respond-max($medium-760px) {
    margin-bottom: 110px;
  }
  
}
.section__heading {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2.5rem;
}
.time {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  @include respond-min($medium-760px) {
    width: 320px;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.chip {
  display: inline-block;
  padding: 6px 20px;
  border-radius: 16px;
  background-color: #f5f5f5;
  cursor: pointer;
  min-width: 70px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.chip.selected {
  // border: 1px solid $primary-color;
  background-color: $primary-color;
  color: $white;
}
// ::-webkit-scrollbar {
//   display: none;
// }
.modal__button {
  width: 100%;
  padding: 2rem;
  font-size: 14px;
  font-weight: 400;
}
.next__button {
  margin-top: 2rem;

  @include respond-max($medium-700px) {
    margin-top: 1.5rem;
    height: 40px;
  }
}
.cancel__button {
  margin-top: 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.selection-dropdown {
  margin-bottom: 2.5rem;
}

.selection-dropdown select {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-bottom: 0.7px solid #d8d8d8;
  font-family: "Poppins", sans-serif;
  margin-left: -4px;
}
select:focus-visible {
  outline: none !important;
}
