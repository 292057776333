.slot-section {
  max-width: 700px;
  margin: auto;
}
.slot {
  // display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6rem 0 6rem 0;
  align-items: center;
  justify-content: center;

  .primary__btn {
    width: 10rem;
    text-transform: capitalize;
    font-size: 1.6rem;
    font-weight: 500;
    // padding: 7px 2.5rem;
    background-image: var(--button-background);
    color: white;
    border-radius: 10px;
    height: 36px;
    cursor: pointer;

    @include respond-max($small-600px) {
      height: 35px;
      width: 10rem;
    }
  }

  &__btn-stack {
    // display: flex;
    align-items: center;

    // padding: 0 20px;

    @include respond-min($large-820px) {
      display: flex;
    }

    & > *:not(:last-child) {
      margin-right: 0.5rem;

      @include respond-max($large-820px) {
        margin-bottom: 0.7rem;
        margin-right: 0;
      }
    }

    @include respond-max($small-600px) {
      flex-wrap: wrap;
      // padding: 0 3rem;
      // height: 12rem;
      justify-content: center;
    }
    .text-field-with-prefix {
      background-color: $white;
      box-shadow: $box-shadow;
      border: none;
      border-radius: 5px;

      .select-wrap select {
        // line-height: 10px;
        padding: 8px 44px !important;
        background-position: calc(100% - 15px) calc(1em + 2.9px),
          calc(100% - 10px) calc(1em + 2.9px), calc(100% - 2.5em) 0.5em;
        @include respond-max($large-820px) {
          padding: 11px 35px !important;
        }
      }
      .select-wrap .prefix-icon {
        top: 10px !important;
        left: 13px !important;

        @include respond-max($large-820px) {
          top: 14px !important;
          left: 12px !important;
        }
      }
    }
    // .form-items .text-field-with-prefix .select-wrap .prefix-icon {
    //   top: 1px;
    // }
    .select__btn {
      padding: 0 1.3rem;
      background-color: $white;
      box-shadow: $box-shadow;
      // border: none;

      .nav__search-icon {
        margin-right: 1.4rem;
      }
    }
  }
}
