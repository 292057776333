.bar__container {
  height: 1rem;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .bar.total {
    width: 100%;
    background-color: lightgray;
  }
  .bar.completed {
    background-color: black;
  }
}
