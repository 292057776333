.footer {
  display: flex;
  flex-direction: column;
  // padding: 4rem 0;
  max-width: $app-max-width;
  margin: auto;
  gap: 2rem;

  @include respond-max($largest-1360px) {
    padding: 2rem 80px;
  }
  @include respond-max($largest-1000px) {
    padding: 2rem 20px;
  }

  // border-top: 1px solid #d8d8d8;

  // @include respond-max($largest-1360px) {
  //   margin: 4rem 0;
  // }
  // @include respond-max($largest-1000px) {
  //   padding: 4rem 0;
  // }
  .store_links {
    margin: 0;
  }
  // .store_links .apple-icon {
  //   width: 140px;
  //   height: 40px;
  //   @include respond-max($largest-1200px) {
  //     width: 100px;
  //     height: 40px;
  //   }
  //   @include respond-max($small-540px) {
  //     width: 100px;
  //     height: 45px;
  //     margin-bottom: 3px;
  //   }
  // }
  // .store_links .google-icon {
  //   width: 160px;
  //   height: 60px;
  //   @include respond-max($largest-1200px) {
  //     width: 130px;
  //     height: 50px;
  //   }
  //   @include respond-max($small-540px) {
  //     width: 120px;
  //     height: 50px;
  //   }
  // }

  .nav__logo {
    padding: 0;
    margin: 0;
    height: 33px;

    @include respond-max($largest-1100px) {
      margin-bottom: 0px;
    }
  }
  .store_links {
    justify-content: space-between;
    margin-top: 0;
  }
  // &__links,
  // &__page-links,
  // &__social-links,
  // &__platform-links {
  //   display: flex;
  //   align-items: center;
  //   // flex: 1;
  //   // gap: 10px;
  // }

  &__links {
    // flex-wrap: wrap;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;

    .so {
      margin-top: 6px;
    }
    @include respond-max($largest-1100px) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    @include respond-max($medium-670px) {
      gap: 0px;
    }
    &__left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      .nav__logo {
        margin-top: 7px;
      }
      @include respond-max($largest-1100px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__page-links {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center; // flex-basis: 56rem;

   // @include respond-max($medium-670px) {
    //  display: flex;
    //  flex-direction: column;
   // }
  }

  &__social-links {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center; // flex-basis: 56rem;

    & > *:not(:last-child) {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    @include respond-max($medium-670px) {
      margin-top: 3rem;
    }
  }

  &__platform-links {
    justify-content: center;
    @include respond-max($larger-930px) {
      margin-top: 1rem;
    }

    @include respond-max($medium-670px) {
      margin-top: 3rem;
    }

    img {
      width: 15rem;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__links {
    & > *:not(:last-child) {
      margin-right: 1rem;

      @include respond-max($small-540px) {
        margin-right: 0;
      }
      color: #ffffff00;
    }
  }

  .social-icon {
    width: 3rem;
    height: 3rem;
  }

  .policy__text {
    margin-top: 0.5rem;
    display: flex;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    color: $primary-text-color;
    justify-content: center;

    & > *:not(:last-child) {
      margin-right: 1rem;
    }

    span {
      color: $primary-color;
      margin-left: 1rem;
    }
  }
  .nav__top-link {
    @include respond-max($medium-670px) {
      font-size: 1.8rem;
    }
  }
}
