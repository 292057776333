.sidebar {
  flex: 0.3;
  flex-direction: column;
  display: flex;
  border-right: 1px solid #d8d8d8;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cecece;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.sidebar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-color;
  padding: 2.2rem;
}
.sidebar_icon {
  width: 2rem;
  height: 2rem;
  fill: $primary-text-color;
}
.sidebar__headerright {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  min-width: 7vw;
  margin-left: 7px;

  .sidebar_icon {
    margin-right: 1rem;
  }
}

.sidebar__search {
  display: flex;
  height: 4rem;
  align-items: center;
  margin: 5px;
}

.sidebar__searchcontainer {
  display: flex;
  background-color: white;
  width: 100%;
  align-items: center;
  overflow: hidden;
  padding: 1rem;
}

.sidebar__searchcontainer > input {
  width: 80%;
  border: none;
  margin-left: 1.5rem;
  color: gray;
}
.sidebar__searchcontainer > .MuiSvgIcon-root {
  margin-left: 1.5rem;
  color: gray;
  font-size: 1.8rem !important;
}

.sidebar__chhatsGroups {
  flex: 1;

  background-color: white;
}
