.divider {
  width: 100%;
  margin: 0 auto;
  padding: 0 17rem;
  height: 0.5px;
  background-color: #d8d8d8;
  position: relative;

  @include respond-max($medium-670px) {
    padding: 0 5rem;
  }

  @include respond-max($small-540px) {
    padding: 0;
    width: 100%;
  }

  &__text {
    padding: 00.2rem 3rem;
    font-size: 3rem;
    font-weight: 400;
    background-color: #fff;
    color: $primary-text-color;
    position: absolute;
    top: -2.6rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    @include respond-max($medium-670px) {
      font-size: 2rem;
    }

    @include respond-max($small-540px) {
      padding: 1rem 2rem;
    }
  }
}
