.business__bookings {
  font-size: 1.6rem;
  position: relative;

  & > * {
    margin-bottom: 6rem;
  }

  @include respond-max($largest-1000px) {
    font-size: 1.2rem;
  }

  .bookings__settings-toggler {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: -4rem;
    color: $primary-text-color;
    fill: $primary-text-color;
    cursor: pointer;

    svg {
      width: 2rem;
      height: 2rem;
      fill: $primary-text-color;
      margin-right: 1rem;
    }
  }
  .bookings__settings-toggler.active {
    color: $primary-color;
    fill: $primary-color;
  }

  .bookings__settings {
    padding: 2rem;

    .secondary-heading {
      text-align: left;
    }

    &,
    .opening__hours-section,
    .vacation-section {
      box-shadow: $box-shadow;
      border-radius: 5px;
    }

    &-cols {
      display: flex;

      @include respond-max($larger-900px) {
        flex-direction: column;
      }
    }
    &-col {
      flex: 1;
    }

    &-col.col-2 {
      padding: 2rem;
      display: flex;
      flex-direction: column;

      @include respond-max($smaller-440px) {
        padding: 2rem 0;
      }

      & > *:not(:last-child) {
        margin-bottom: 3rem;
      }

      .switch__btn-form-group {
        display: flex;
        align-items: center;
        .switch__btn {
          margin-right: 2rem;
        }
      }

      .btn__stack {
        display: flex;
        align-items: center;
        margin-top: auto;
        width: 100%;
        justify-content: space-around;

        & > *:first-child {
          @include respond-max($smaller-440px) {
            margin-right: 2rem;
          }
        }
      }
    }

    .opening__hours-section,
    .vacation-section {
      padding: 1rem;
    }
    .vacation-section {
      margin-top: 3rem;
    }

    .week__forms {
      display: flex;
      flex-direction: column;
    }

    .week__form-group {
      display: flex;
      align-items: center;
      padding: 2rem 0;
      margin: 0 4rem;

      @include respond-max($largest-1200px) {
        margin: 0;
      }

      @include respond-max($smaller-440px) {
        flex-direction: column;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #d8d8d8;
      }

      .checkbox__form-group {
        flex: 1;
        align-self: flex-start;

        @include respond-max($smaller-440px) {
          margin-bottom: 2rem;
        }
      }

      .primary-checkbox-label {
        font-size: 1.6rem;
      }

      .select__form-group .select {
        padding-right: 7rem;
      }
    }

    .week__time-selectors {
      flex: 2;

      @include respond-max($smaller-440px) {
        width: 90%;
      }
    }
    .week__select-inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    .select__field-remover {
      width: 2rem;
      height: 2rem;
      fill: $primary-text-color;
    }

    .add__week-field-btn {
      color: $primary-color;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;

      img,
      svg {
        width: 1.4rem;
        height: 1.4rem;
        fill: $primary-color;
        margin-right: 5px;
      }
    }
  }
  .week__form-group.disabled {
    position: relative;
    z-index: -1;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffff7c;
    }
  }
}
.booking_header {
      color: black;
      font-size: 30px;
      font-weight: 500;
}
.booking_subheader {
      color: grey;
      font-size: 13px;
}
.booking_grey_text {
      color: grey;
      font-size: 13px;
}
.booking_black_text {
      color: black;
      font-size: 13px;
}
.booking_check_icon {
      width: 4rem;
      height: 4rem;
}
.booking_details_card {
  margin-top: 20px;
  display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    text-align: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    min-height: 30rem;
    width: 40rem;
    padding: 10px;
    border-radius: 10px;
      @include respond-max($smaller-440px) {
        width: 70vw;
      }    
}
.booking_details_container {
  justify-content: center;
    display: grid;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

