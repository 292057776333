.business__settings {
  padding: 3rem;
  border-radius: 10px;
  width: 82rem;

  @include respond-max($medium-760px) {
    width: 100%;
  }

  @include respond-max($small-540px) {
    padding: 1rem;
  }

  &-form {
    background-color: #f8f8f8;

    @include respond-max($small-540px) {
      padding: 5rem 2rem !important;
    }
  }

  input {
    background: transparent;
    outline: none;
  }

  .primary__input {
    background-color: #fff;
    width: 100%;
  }

  .business__inputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include respond-max($smaller-410px) {
      .form__group {
        width: 100%;
        flex: none !important;
      }
    }

    & > .form__group:first-child {
      width: 100%;

      .primary__input {
        width: 100%;
        background-color: #fff;
      }
    }

    & > *:not(:first-child) {
      flex: 1;
    }

    & > *:nth-child(2) {
      margin-right: 2rem;
    }
  }

  .invite__section {
    .tertiary-heading {
      padding: 2rem 0;
      background-color: #f8f8f8;
      text-align: center;
    }

    .invite__cols {
      display: flex;

      @include respond-max($smaller-410px) {
        flex-direction: column;
      }
    }
    .invite__col {
      padding: 2rem;
      flex: 1;
      color: $primary-text-color;

      @include respond-max($smaller-410px) {
        padding: 2rem 0;
      }

      &:first-child {
        border-right: 1px solid #d8d8d8;

        @include respond-max($smaller-410px) {
          border-right: none;
          border-bottom: 1px solid #d8d8d8;
        }
      }

      p,
      span {
        font-size: 1.3rem;
      }

      span {
        display: flex;
        align-items: center;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        padding: 1rem;
        margin-top: 4rem;

        img {
          width: 2rem;
          margin-right: 1rem;
        }
      }
    }
    .col__heading {
      font-size: 1.6rem;
      font-weight: 600;
      color: $primary-text-color;
    }
    .method__items {
      display: flex;
      flex-direction: column;
    }
    .method__item {
      display: flex;
      align-items: center;
      padding: 1rem 0;

      svg {
        width: 3rem;
        height: 3rem;
        fill: $primary-text-color;
        margin-right: 1rem;
      }
    }

    .primary__btn {
      margin: 2rem auto;
    }
  }
}
