.favourites__card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 22%;

  @include respond-max($small-600px) {
    width: 30%;
  }
  @include respond-max($small-540px) {
    width: 40%;
  }

  .favourites__locked-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    border: none;
    background-color: #fff;
    padding: 5px;
    border-radius: 50%;
  }

  .favourites__image {
    height: 16rem;
    border-radius: 2rem;
  }

  .favourites__text-content {
    padding: 1rem;
  }
}
