.category__section {
  // margin-top: 22rem;

  .recommended-section {
    max-height: none;
    @include respond-max($largest-1360px) {
      padding: 0 80px 5rem 80px;
    }
    @include respond-max($largest-1000px) {
      padding: 0 20px 5rem 20px;
    }
  }
}
.category__name {
  margin-bottom: 2px;
  font-size: 2rem;
  text-transform: capitalize;
}
.category__description {
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
.no__recommended {
  display: block;
  text-align: center;
  font-size: 2rem;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  @include respond-max($smaller-470px) {
    width: 55px;
    height: 26px;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.category__toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.category__toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  @include respond-max($smaller-470px) {
    height: 18px;
    width: 18px;
  }
}

input:checked + .category__toggle {
  background-color: $primary-color;
}

input:focus + .category__toggle {
  box-shadow: 0 0 1px $primary-color;
}

input:checked + .category__toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded category__toggle */
.category__toggle.round {
  border-radius: 34px;
}

.category__toggle.round:before {
  border-radius: 50%;
}
.available__today {
  display: flex;
  gap: 2rem;
  margin-bottom: 18px;
}
.available__today__text {
  color: #535353;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
}
