@import "./chatRoom";
@import "./chats";
@import "./sidebar";

.chat__container {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-left: 10px;
  display: flex;
  width: 77%;
  height: 73vh;
  background-color: #fafafa;
  position: relative;

  @include respond-max($largest-1200px) {
    width: 100%;
    margin-left: 0;
  }
  @include respond-max($medium-730px) {
    height: 74vh;
  }

  & > * {
    @include respond-max($small-600px) {
      position: absolute;
      width: 100%;
    }
  }
}
