.contact__container {
  padding-top: 14rem;
  .contact__main {
    max-width: 100%;
    // height: 100vh;
    padding: 5rem 20px;
    padding-top: 10px;
    margin: 0;
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    // grid-template-columns: 60% 40%;
    @include respond-max($largest-1000px) {
      flex-direction: column;
    }
  }
  .details_container {
    padding: 0 15px;
    margin-left: 25%;    
  }

  .form__container {
    border-radius: 5px;
    width: 100%;
    padding: 0 15px;
  }
  .contact {
    color: #535353;
    margin: 0;
   // text-transform: uppercase;
    font-size: 30px;
    font-weight: 400;
    // letter-spacing: 5px;
  }
  .still__text {
    color: #535353;
    margin: 0;
    font-weight: 400;
    line-height: 30px;
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 40px;
  }
  .contact__form__content {
    // width: 100%;

    //wrap
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 5rem;
    justify-content: center;
  }

  .user__name {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .user__input {
    width: 50%;

    @include respond-max($largest-1100px) {
      width: 100%;
    }
    .primary__input {
      width: 100%;
    }
  }

  .text__area textarea {
    border: none;
  }

  .text__center {
    text-align: center;
    display: block;
  }
  .submit input[type="submit"] {
    letter-spacing: 4px;
  }

  @media screen and (max-width: 550px) {
    .user__name {
      display: block;
    }
    .user__input {
      width: 100%;
    }
  }
  .flex__start {
    display: flex;
    justify-content: flex-start;
  }
  .contact__text {
    padding: 0px 10px;
    font-size: 16px;
    color: #535353;
  }
  .contact-icons {
    fill: #535353 !important;
    margin-top: 5px;
  }
  .top__20 {
    margin-top: 20px;
  }
  .info {
    position: absolute;
  }
  .bottom_20 {
    margin-bottom: 20px;
  }

  .social_icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  .terms__privacy {
    font-size: 14px;
    font-weight: 400;
    color: #535353;
    text-decoration: none !important;
    margin-right: 12px;
  }
}
.contact_form_input {
    padding: 0.5rem 2rem;
}

.contact_send_button {
    font-size: 2rem !important;
}