.calendar__container {
  width: 100%;
  height: 80vh;

  @include respond-max($medium-670px) {
    height: 60vh;
  }
  @include respond-max($small-540px) {
    height: 40vh;
  }
}
.rbc-toolbar {
  & > *:not(:last-child) {
    @include respond-max($medium-670px) {
      margin-bottom: 1rem;
    }
  }

  .rbc-btn-group {
    order: 1;
    & > * {
      font-size: 1.6rem;
    }

    &:last-of-type {
      display: none;
    }
  }
  .rbc-toolbar-label {
    font-size: 1.6rem;
    margin-right: auto;
    flex: none;
    // @include respond-max($small-540px) {
    //   width: 100%;
    //   margin: 0;
    // }
  }
}
