.services-section {
  padding: 5rem 17rem;

  @include respond-max($largest-1260px) {
    padding: 4rem 8rem;
  }

  @include respond-max($medium-670px) {
    padding: 4rem;
  }

  .search__text {
    font-size: 1.6rem;
    font-weight: 600;
    color: $primary-text-color;
  }

  .category__row {
    grid-template-columns: repeat(6, 1fr);
    display: grid;
    text-align: left;
    margin: 0 auto;
    overflow-x: auto;
    grid-gap: 16px;

    scrollbar-width: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
    & > *:not(:last-child) {
     // margin-right: 2rem;

      @include respond-max($medium-670px) {
        margin-right: 1rem;
      }
    }
  }
  .service__card {
    width: 20.1rem;
    border: 1px solid #d8d8d8;
    overflow: hidden;

    .service__title {
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
      padding: 1rem;
    }
  }

  .view-all-text {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: $primary-text-color;
  }
  .chip {
    height: 60px;
    
    line-height: 60px;
    border-radius: 25px;
    width: 100%;
    text-align: center;
    background-color: #F9F9F9;
    padding: 0px 10px;

  }
   .chip_span {
    display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  color: #0AC5B8;
  font-size: 16px;
  font-weight: 400;
  }
}
