.welcome-section {
  display: flex;
  box-shadow: $box-shadow;
  height: 30rem;
  overflow: hidden;
  position: relative;

  @include respond-max($medium-640px) {
    height: auto;
  }

  img {
    transform: scale(1.4);

    @include respond-max($large-820px) {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      z-index: -1;
      opacity: 0.3;
    }
  }

  & > * {
    flex: 1;
  }

  .welcome__text {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    color: $primary-text-color;

    .welcome__slogan,
    .welcome__note {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.4rem;
    }
    .welcome__heading {
      font-size: 3.5rem;
      font-weight: 700;
      line-height: 4.3rem;
    }
    .primary__btn {
      align-self: flex-start;
      margin-top: auto;
      width: 32rem;
      text-align: left;
    }
  }
}

// * PROFILE SECTION
.profile-section,
.profile__form-input,
.profile__opt-text-input,
.select__form-group .select,
.profile__form-textarea,
.profile__more-info,
.profile__portfolio,
.portfolio__image-form-group,
.profile__location {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.profile-section {
  margin-bottom: 10rem;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  input:focus,
  textarea:focus {
    outline: 1px solid $primary-color;
  }

  // * HEADER
  .profile__header {
    display: flex;
    align-items: center;
    padding: 2rem;

    &-icon {
      width: 3rem;
      margin-left: auto;

      @include respond-max($smaller-410px) {
        display: none;
      }
    }
  }
  .profile__heading {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
    color: $primary-text-color;
  }
  .primary__btn {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: 50rem;

    &-text {
      @include respond-max($medium-670px) {
        display: none;
      }
    }

    @include respond-max($large-820px) {
      width: auto;
    }

    img {
      width: 3rem;
      margin-right: 1rem;

      @include respond-max($medium-670px) {
        margin: 0;
      }
    }
  }

  // * FORM
  .profile__form-cols {
    width: 100%;
    display: flex;
    padding: 2rem;

    @include respond-max($medium-640px) {
      flex-direction: column;
    }
  }
  .profile__form-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-right: 2rem;

      @include respond-max($medium-640px) {
        margin: 0;
        margin-bottom: 2rem;
      }
    }
    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .profile__form-input,
  .profile__opt-text-input {
    font-family: inherit;
  }
  .profile__form-input {
    padding: 1rem;
    font-size: 1.6rem;
  }
  .select__form-group {
    .select {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #d8d8d8;
      padding: 1rem;
    }
  }

  .profile__more-info {
    padding: 1rem;
    flex: 1;
  }
  .profile__form-group {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  .profile__form-label {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
    color: $primary-text-color;
    margin-bottom: 1rem;
  }
  .profile__opts {
    display: flex;

    & > *:not(:last-child) {
      margin-right: 2rem;
    }
  }

  .profile__opt-form-group {
    display: flex;
    align-items: center;
  }

  .profile__opt-text-input {
    padding: 5px;
    font-size: 1.2rem;
  }

  // * PORTFOLIO
  .profile__portfolio {
    margin: 2rem;

    .profile__header {
      border-bottom: 1px solid #d8d8d8;
    }

    .image__upload-types {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 2.2rem;
      color: #d8d8d8;

      @include respond-max($medium-640px) {
        font-size: 1.2rem;
      }
    }

    &-creator {
      padding: 2rem;
    }
    color: $primary-text-color;
    .portfolio__creator {
      &:not(:last-child) {
        margin-top: 4rem;
      }

      &-header {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.2rem;
      }
      &-date {
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: #d8d8d8;
      }

      // * IMAGE GALLERY
      &-image-gallery {
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;
      }
      .portfolio__image-form-group {
        width: 14rem;
        height: 14rem;
        overflow: hidden;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-right: 2rem;
        }

        .portfolio__image-preview,
        .portfolio__image-preview img {
          width: 100%;
          height: 100%;
          position: relative;
        }

        .portfolio__image-delete {
          position: absolute;
          top: 1rem;
          right: 1rem;
          background-color: red;
          height: 2rem;
          width: 2rem;
          z-index: 1;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          cursor: pointer;

          svg {
            width: 2rem;
            height: 2rem;
            fill: #fff;
          }
        }
      }
      .portfolio__upload-image-input {
        display: none;
      }
      .portfolio__upload-image-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img,
        svg {
          width: 5rem;
          height: 5rem;
          fill: $primary-text-color;
        }
      }
      .add__more-btn {
        font-size: 9px;
      }
    }
    .add__more-btn {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      font-size: 1.6rem;
      color: $primary-text-color;
      cursor: pointer;

      img,
      svg {
        width: 2rem;
        height: 2rem;
        fill: $primary-text-color;
      }
    }
  }

  .profile__location {
    margin: 2rem;
    position: relative;

    .profile__header {
      border-bottom: 1px solid #d8d8d8;
    }

    .location__info {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    .map {
      width: 100%;
      height: 50rem;
    }

    &-bottom {
      padding: 1rem;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: $primary-color;
      position: absolute;
      bottom: 0;
      left: 0;
      color: white;
    }

    .location__bottom-text {
      font-size: 2.3rem;
      font-weight: 500;
      line-height: 2.8rem;
    }
  }

  .btn__stack {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 5rem 0 3rem 0;

    @include respond-max($smaller-410px) {
      padding: 1rem 2rem;
    }

    .primary__btn {
      width: 20rem;
      margin: 0;

      @include respond-max($smaller-410px) {
        flex: 1;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
}
