.favourites-section {
  // padding: 5rem 8rem;
  // display: flex;
  // align-items: flex-start;

  // @include respond-min($screen-1550px) {
  //   padding: 5rem 12rem;
  // }

  // @include respond-max($largest-1100px) {
  //   padding: 5rem;
  // }

  // @include respond-max($larger-900px) {
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;

  //   .user__nav {
  //     align-self: center;
  //     margin-bottom: 4rem;
  //   }
  // }

  @include respond-max($largest-1200px) {
    width: 100%;
  }

  .favourites {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    width: 69vw;

    @include respond-min($screen-1550px) {
      width: 68vw;
    }

    @include respond-max($largest-1200px) {
      width: 100%;
    }

    .secondary-heading {
      text-align: left;
    }

    &__actions {
      display: flex;

      &-icon {
        width: 2rem;
        height: 2rem;
        fill: $primary-text-color;

        &:nth-child(2) {
          margin-right: auto;
        }

        &:not(:last-child):not(:nth-child(2)) {
          margin-right: 1rem;
        }
      }
    }

    &__items-container {
      padding: 4rem;

      & > *:not(:last-child) {
        margin-bottom: 1rem;
      }

      @include respond-max($large-820px) {
        padding: 4rem 0;
      }
    }

    &__items {
      display: flex;
      justify-content: center;

      & > *:not(:last-child) {
        margin-right: 2rem;
      }

      & > * {
        @include respond-max($small-540px) {
          &:nth-child(3) {
            display: none;
          }
        }
        @include respond-max($small-600px) {
          &:last-child {
            display: none;
          }
          &:not(:nth-child(2)) {
            margin-right: 2rem;
          }
        }
        @include respond-max($large-820px) {
          &:not(:nth-child(3)) {
            margin-right: 2rem;
          }
        }
      }
    }

    &__catalog {
      .recommended__card {
        padding: 1rem;

        .recommended__title {
          font-size: 1.7rem;

          @include respond-min($screen-1550px) {
            font-size: 2rem;
          }
        }
        .recommended__class {
          font-size: 1.5rem;

          @include respond-min($screen-1550px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}
