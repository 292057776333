.secondary-heading {
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.7rem;
  text-align: center;
  color: $primary-text-color;
}
.tertiary-heading {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.2rem;
  color: $primary-text-color;
}
