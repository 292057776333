.faq_box {
  max-width: $largest-1360px;
  margin: 4rem auto;

  .collapse_tile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-family: $primary-font-stack;
    color: $primary-text-color;
    font-size: 15px;

    .box {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      .text_opened {
        color: $primary-text-color;
        font-weight: bold;
      }
      .text_closed {
        color: $primary-text-color;
        font-weight: 500;
      }
      .plusminus {
        position: relative;
        width: 12px;
        height: 12px;
        cursor: pointer;

        &.active {
          &:before {
            transform: translatey(-50%) rotate(-90deg);
            opacity: 0;
          }
          &:after {
            transform: translatey(-50%) rotate(0);
          }
        }

        &:before,
        &:after {
          content: "";
          display: block;
          background-color: $primary-text-color;
          position: absolute;
          top: 50%;
          left: 0;
          transition: 0.35s;
          width: 100%;
          height: 2.5px;
        }

        &:before {
          transform: translatey(-50%);
        }

        &:after {
          transform: translatey(-50%) rotate(90deg);
        }
      }
    }
  }
  .collapse_desc {
    text-align: justify;
    text-justify: inter-word;
    margin-left: 0;
    width: auto;
    color: $grey;
    padding: 20px;
    font-size: 14px;
    font-weight: 400;
    overflow-y: auto;
    max-height: 20rem;
    margin-bottom: 10px;
    p {
      margin: 10px 0;
    }
    ul {
      margin: 20px 20px 20px 17px;
    }
    li {
      margin: 10px 0;
    }
  }

  .Collapsible__trigger {
    cursor: pointer;
    display: block;
    padding: 13px 10px;
    position: relative;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;

    font-weight: bold;
    margin-bottom: 10px;
    background-color: $white;
    box-shadow: $box-shadow;
    // border: 2px solid $primary-color;
  }
}
