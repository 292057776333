.checkbox__form-group {
  display: flex;
  align-items: center;
  .primary-checkbox {
    display: none;
  }
  .primary-checkbox:checked
    ~ .primary-checkbox-label
    .primary-checkbox-replacer {
    background-color: $primary-color;
  }
  .primary-checkbox-replacer {
    margin-right: 5px;
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 50%;
    border: 3px solid $primary-color;
  }
  .primary-checkbox-label {
    font-size: 1.2rem;
    font-weight: 500;
    color: $primary-text-color;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
