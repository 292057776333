.mg-bottom-small {
  margin-bottom: 1rem !important;
}
.mg-bottom-medium {
  margin-bottom: 3rem !important;
}
.mg-bottom-large {
  margin-bottom: 5rem !important;
}
.mg-bottom-xl {
  margin-bottom: 8rem !important;
}

.mg-top-small {
  margin-top: 1rem !important;
}
.mg-top-medium {
  margin-top: 3rem !important;
}
.mg-top-large {
  margin-top: 5rem !important;
}
.mg-top-xl {
  margin-top: 8rem !important;
}

.mg-left-small {
  margin-left: 1rem !important;
}
.mg-left-medium {
  margin-left: 3rem !important;
}
.mg-left-large {
  margin-left: 5rem !important;
}
.mg-left-xl {
  margin-left: 8rem !important;
}

.mg-right-small {
  margin-right: 1rem !important;
}
.mg-right-medium {
  margin-right: 3rem !important;
}
.mg-right-large {
  margin-right: 5rem !important;
}
.mg-right-xl {
  margin-right: 8rem !important;
}

.mg-around-small {
  margin: 1rem !important;
}
.mg-around-medium {
  margin: 3rem !important;
}
.mg-around-large {
  margin: 5rem !important;
}
.mg-around-xl {
  margin: 8rem !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.align-item-center {
  align-items: center;
}
