@mixin respond-max($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin respond-min($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}
@mixin appmodal($height, $width) {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  &-content {
    @if $width {
      width: $width;
    } @else {
      width: 80vw;
    }

    height: $height;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;

    overflow-y: auto;
    overflow-x: hidden;
  }
  &-close-icon {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}