.client-account-bookings-section {
  flex: 1;

  // ! BUSINESS CHANGES
  color: $primary-text-color;

  .bookings__nav,
  .bookings__filters,
  .bookings__search,
  .form__group,
  .primary__btn,
  .bookings__row {
    display: flex;
    align-items: center;

    .preview-btn {
      // width: 100px;
      padding: 0 10px;
      height: 35px;
      text-align: center;
      color: $white;
      font-size: 15px;
      border-radius: 10px;
      background-color: $primary-color;
      margin-left: auto;
      margin-right: 2rem;
      &:hover {
        opacity: 0.7;
      }

      @include respond-max($smaller-470px) {
        padding: 0 5px;
        height: 25px;
        font-size: 10px;
        border-radius: 5px;
      }
    }
  }

  .bookings__container {
    @include respond-max($largest-1100px) {
      padding: 0 0.5rem;
    }
  }

  .bookings__nav {
    padding: 1rem 3rem;
    flex-wrap: wrap;

    @include respond-max($small-600px) {
      padding: 0;
    }
  }

  .bookings__nav-icon {
    width: 2.5rem;
  }

  .bookings__search,
  .primary__btn {
    // flex: 1;
    // width: 10px;
  }

  .bookings__filters {
    list-style-type: none;
    flex: 2;

    @include respond-max($larger-900px) {
      width: 100%;
      order: 1;
      justify-content: center;
      margin-top: 2rem;
      flex: none;
    }
  }

  .bookings__filter {
    font-size: 1.4rem;
    padding: 1rem 0;
    box-sizing: content-box;
    text-align: center;
    cursor: pointer;
    @include respond-max($larger-900px) {
      flex: 1;
    }

    @include respond-max($smaller-470px) {
      font-size: 1.2rem;
    }

    &-active {
      font-weight: 700;
      border-bottom: 3px solid $primary-color;
    }
    &:hover {
      opacity: 0.7;
    }
    &:not(:last-child) {
      margin-right: 2rem;

      @include respond-max($larger-900px) {
        margin-right: 1rem;
      }
    }
  }

  .form__group {
    border: 1px solid #d8d8d8;
    padding: 1rem;
    border-radius: 2rem;
    margin-right: 2rem;

    @include respond-max($larger-900px) {
      margin-right: 5px;
      padding: 5px;
      width: 100%;
    }
  }
  .bookings__search-input {
    border: none;
    padding: 1rem;
    font-size: 1.4rem;
    color: $primary-text-color;
    flex: 1;
    outline: none;

    @include respond-max($medium-730px) {
      font-size: 1.2rem;
    }
    @include respond-max($larger-900px) {
      font-size: 1.6rem;
    }
  }

  .primary__btn {
    padding: 1.4rem 0;
    border-radius: 2rem;
    font-size: 1.4rem;
    display: flex;
    align-items: center;

    @include respond-max($smaller-410px) {
      span {
        display: none;
      }
    }
  }

  .bookings {
    box-shadow: $box-shadow;
    padding: 1rem;
    border-radius: 10px;
    overflow-x: scroll;
    width: 68vw;

    margin-left: 2rem;

    @include respond-min($screen-1550px) {
      width: calc(100vw - 60rem);
    }

    @include respond-max($largest-1200px) {
      width: 93vw;
      margin: 0;
      margin-top: 10px;
      margin-left: 0;
    }

    &__row {
      width: 100%;

      padding: 2rem 1rem;
      position: relative;
      // display: flex;
      // flex-direction: row;
      // gap: 10px;

      .opts-icon {
        width: 2rem;
        height: 2rem;
        margin-left: auto;
        // margin-right: 2rem;
        fill: $primary-text-color;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #d8d8d8;
      }
    }

    &__row-group {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      width: 15%;
      padding: 0 2rem;
      flex-shrink: 0;

      // @include respond-max($largest-1000px) {
      //   width: 15%;
      // }

      // @include respond-max($large-800px) {
      //   width: 20%;
      // }

      @include respond-max($small-600px) {
        font-size: 1.3rem;
      }

      @include respond-max($smaller-470px) {
        font-size: 1.2rem;
        padding: 0 1rem;
      }
      &-medium {
        width: 25%;
        overflow: hidden;
      }
      &-large {
        width: 40%;
        overflow: hidden;

        // @include respond-max($largest-1000px) {
        //   width: 25%;
        // }

        // @include respond-max($large-800px) {
        //   width: 35%;
        // }
      }
    }

    .bookings__row.paging__row {
      flex-wrap: wrap;
    }

    .sort__icon {
      width: 1.3rem;
    }

    .guest__image {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;

      @include respond-max($smaller-470px) {
        width: 3rem;
        height: 3rem;
      }
    }
    .guest__meta {
      display: flex;
      flex-direction: column;

      .guest__name {
        font-size: inherit;
        font-weight: 500;
      }
    }
    .guest__link {
      text-decoration: none;
      font-size: inherit;
      text-align: center;

      &-blue {
        color: #0099ff;
      }
      &-red {
        color: red;
      }
      &-green {
        color: #03e203;
      }
      &-gray {
        color: gray;
      }
      &-yellow {
        color: #e4e400;
      }
    }

    .guest__date {
      display: flex;
      flex-direction: column;

      .guest__day {
        font-weight: 600;
        font-size: inherit;
      }
      .guest__time {
        font-size: 9px;
      }
    }

    .display__indicator {
      font-size: 1.4rem;
      font-weight: 600;
      margin-right: auto;
      align-self: flex-end;

      @include respond-max($smaller-470px) {
        width: 100%;
        text-align: center;
        padding: 1rem 0 2rem 0;
      }
    }
    .paging {
      display: flex;
      align-items: center;
      @include respond-max($smaller-470px) {
        width: 100%;
        justify-content: space-around;
      }
    }

    .total__pages {
      display: flex;
      font-size: 1.5rem;
    }

    .page {
      padding: 1rem 1.5rem;
      margin: 0 2px;
      border-radius: 10px;

      &-active {
        background-color: $primary-color;
        color: white;
      }
    }

    .select__btn {
      border: 1px solid $primary-color;
      color: $primary-color;
      margin: 0 10px;
      img {
        width: 1.4rem;

        @include respond-max($medium-670px) {
          width: 1.2rem;
        }
      }

      @include respond-max($medium-670px) {
        padding: 1rem;
      }
    }
  }
}
    .booking-tile {
      padding: 10px;
      cursor: pointer;
      box-shadow: $box-shadow;
      margin-top: 10px;
      margin-bottom: 15px;
      border-radius: 15px;
    }

    .guest__meta {
      display: flex;
      flex-direction: column;
      .dot {
        width: 4px;
        height: 4px;

        border-radius: 50%;
        background-color: $black;
        justify-content: center;
        display: flex;
        align-items: center;
      }
      .guest__name {
        font-size: 1.6rem;
        // font-size: inherit;
        font-weight: bold;
      }
      .booking-date {
        margin: 4px 0 2px 0;
        font-size: 1.2rem;
      }
      .service-detail {
        font-size: 1.4rem;
        font-weight: 600;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
      }
    }
    .guest__link {
      text-decoration: none;
      font-size: inherit;
      text-align: center;
      color: $white;
      background-color: $primary-color;
      border-radius: 20px;
      padding: 4px 7px;
      font-size: 12px;
      // &-blue {
      //   color: #0099ff;
      // }
      // &-red {
      //   color: red;
      // }
      // &-green {
      //   color: #03e203;
      // }
      // &-gray {
      //   color: gray;
      // }
      // &-yellow {
      //   color: #e4e400;
      // }
    }

    .guest__date {
      display: flex;
      flex-direction: column;

      .guest__day {
        font-weight: 600;
        font-size: inherit;
      }
      .guest__time {
        font-size: 9px;
      }
    }
        .guest__image {
      width: 7rem;
      height: 7rem;
      border-radius: 100%;
      object-fit: cover;
    }
          .service-detail {
        font-size: 1.4rem;
        font-weight: 600;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
      }    